import React from 'react';
import clsx from 'clsx';

import styles from './style.module.css';

const Checkbox = React.forwardRef((props, ref) => {
  const { className, ...others } = props;
  return (
    <input className={clsx(styles.checkbox, className)} type="checkbox" {...others} ref={ref} />
  );
});

export default Checkbox;
