import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Loading = (props) => {
  if (props.error || props.timedOut) {
    // error
    return null;
  }

  if (props.pastDelay) {
    // loading
    return null;
  }

  // nothing
  return null;
};

Loading.propTypes = {
  error: PropTypes.object,
  retry: PropTypes.func,
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool
};

Loading.defaultProps = {
  error: null,
  retry: () => {},
  pastDelay: false,
  timedOut: false
};

export default Loading;

