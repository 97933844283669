import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import CustomizeModal from 'src/components/CustomizeModal';
import styles from './styles.module.css';

import closeBtn from '../../../../../assets/btn_close2@btnClose.png';

@observer
class SectionD extends React.Component {
  render() {
    const viewModel = this.props.viewModel;
    const otherImg = viewModel.imageList.map((img) => (
      <div
        className={clsx(styles.chooseImg, viewModel.detailImage.id === img.id ? styles.select : null)}
        onClick={() => viewModel.showDetail(img.id)}
        key={img.id}
      >
        <img src={img.value} alt="public" />
      </div>
    ));
    return (
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.sectionNameContainer}>
            <div className={styles.nameEg}>{viewModel.public1}</div>
            <div className={styles.nameCt}>{viewModel.public2}</div>
          </div>
          {
            viewModel.imageList.map((img) => {
              const stylesList = [styles.picContainer1, styles.picContainer2, styles.picContainer3, styles.picContainer4, styles.picContainer5, styles.picContainer6];
              return (
                <div
                  key={img.id}
                  className={clsx(styles.picContainer, stylesList[img.id - 1])}
                  onClick={() => viewModel.showDetail(img.id)}
                >
                  <LazyLoadImage
                    alt=""
                    height="100%"
                    width="100%"
                    src={img.value}
                    wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
                  />

                </div>
              );
            })
          }
          <div className={styles.ovalContainer} />
        </div>
        {/* modal */}
        <CustomizeModal
          shouldIgnorePuppeteer
          className={clsx(styles.modal, viewModel.popup.isShow ? styles.show : styles.noShow)}
          bgColor="#fff"
        >
          <div
            className={styles.modalContainer}
          >
            <div className={styles.modalImageContainer}>
              <div
                className={styles.closeBtnContainer}
                onClick={viewModel.popup.hideModal}
              >
                <img src={closeBtn} alt="close" />
              </div>
              <div
                className={styles.pre}
                onClick={viewModel.previousImg}
              />
              <div
                className={styles.next}
                onClick={viewModel.nextImg}
              />
              <img src={viewModel.detailImage.value} className={styles.modalImage} alt="modalImg" />
            </div>
            <div className={styles.listContainer}>
              <div className={styles.imgList}>
                {otherImg}
              </div>
            </div>
          </div>
        </CustomizeModal>
      </div>
    );
  }
}

SectionD.propTypes = {
  viewModel: PropTypes.object
};

SectionD.defaultProps = {
  viewModel: null
};

export default SectionD;
