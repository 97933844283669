import Door from './Door';
import Label from './Label';

export default class Floor {
  constructor(params) {
    const { id, name, announcement, doors, labels } = params ?? {};

    this.id = id;
    this.name = name;
    this.announcement = announcement;
    this.doors = doors?.map((door) => Door.fromRes(door)) ?? [];
    this.labels = labels?.map((label) => Label.fromRes(label)) ?? [];
  }

  get isDisable() {
    return this.doors.every((door) => !door.isActive || door.isDisable);
  }

  get isActive() {
    return this.doors.some((door) => door.isActive);
  }

  static fromRes(data) {
    return new Floor(data);
  }
}
