import React from 'react';
import { getYouTubeId } from 'src/utils';

export default class CustomizseYouTube extends React.Component {

  constructor(props) {
    super();

    this.state = { videoId: null, playerReady: false };
    this.player = null;

  }

  componentDidMount() {
    const { value } = this.props;
    const id = getYouTubeId(value);
    this.setState({ videoId: id });

    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded

      window.onYouTubeIframeAPIReady = this.loadVideo;
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      this.loadVideo();
    }
  }

  loadVideo = () => {
    const opts = {
      width: '100%',
      height: '100%',
      playerVars: {
        autoplay: 1,
        mute: 1,
        loop: 1,
        rel: 0,
        playlist: this.state.videoId
      },
      enablejsapi: 1,
      events: {
        onReady: this.onPlayerReady
      }
    };

    this.player = new window.YT.Player(`ytplayer-${this.state.videoId}`, opts);
  };


  onPlayerReady = (event) => {
    event.target.pauseVideo();
    this.setState({ playerReady: true });
  };

  onEnter = () => {
    this.play();
  }

  onLeave = () => {
    this.pause();
    this.reset();
  }

  play = () => {
    if (this.state.playerReady) {
      this.player.playVideo();
    }
  } ;

  pause = () => {
    if (this.state.playerReady) {
      this.player.pauseVideo();
    }
  } ;

  reset = () => {
    if (this.state.playerReady) {
      this.player.seekTo(0);
    }
  } ;


  render() {
    if (!this.state.videoId) {
      return null;
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div id={`ytplayer-${this.state.videoId}`} />
      </div>
    );
  }
}
