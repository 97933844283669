import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import Pxcode from './Promo_004';

@observer
class SectionF extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div>
        <Pxcode
          indexdesign1={viewModel.indexDesign1}
          indexdesign2={viewModel.indexDesign2}
          indexdesign3={viewModel.indexDesign3}
          indexdesign4={viewModel.indexDesign4}
          indexdesign5={viewModel.indexDesign5}
        />

        <div className={styles.diagram_box}>設計風格示意圖</div>
      </div>
    );
  }
}

SectionF.propTypes = {
  viewModel: PropTypes.object
};

SectionF.defaultProps = {
  viewModel: null
};

export default SectionF;
