import { observable, action, makeObservable, computed } from 'mobx';

import { SectionType } from 'src/constants';
import CarouselViewModel from 'src/viewModels/Carousel';
import ISection from './ISection';

export default class SectionB3ViewModel extends ISection {
  @observable id;
  @observable slogan;
  @observable picture;
  @observable picturebg;
  @observable labeleg;
  @observable label;
  @observable labelct;
  @observable title;
  @observable description;
  @observable name;
  @observable matIcon1;
  @observable matIcon2;
  @observable matIcon3;
  @observable mat1;
  @observable mat2;
  @observable mat3;

  get type() {
    return SectionType.B3;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    this.deserialize(props);
    this.carouselViewModel = new CarouselViewModel(this.picture);
  }

  onEnter = () => {
    this.carouselViewModel.onEnter?.();
  };

  onLeave = () => {
    this.carouselViewModel.onLeave?.();
  };

  @action
  deserialize = (params) => {
    const {
      id,
      slogan,
      picture,
      picturebg,
      labeleg,
      label,
      labelct,
      title,
      description,
      name,
      mat_icon1: matIcon1,
      mat_icon2: matIcon2,
      mat_icon3: matIcon3,
      mat1,
      mat2,
      mat3
    } = params ?? {};

    this.id = id;
    this.slogan = slogan;
    this.picture = picture;
    this.picturebg = picturebg;
    this.labeleg = labeleg;
    this.label = label;
    this.labelct = labelct;
    this.title = title;
    this.description = description;
    this.name = name;
    this.matIcon1 = matIcon1;
    this.matIcon2 = matIcon2;
    this.matIcon3 = matIcon3;
    this.mat1 = mat1;
    this.mat2 = mat2;
    this.mat3 = mat3;
  };

  @computed
  get activeIndex() {
    return this.carouselViewModel.activeIndex;
  }

  @computed
  get isLastIndex() {
    return this.activeIndex === this.carouselViewModel.slideRefs.length - 1;
  }
}
