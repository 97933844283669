import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Pxcode from './Promo_002_final';
import Carousel from '../../Carousel';

import styles from './styles.module.css';

@observer
class SectionB1 extends Component {
  constructor(props) {
    super(props);
    this.viewModel = props.viewModel;
  }

  render() {
    const { viewModel } = this.props;
    const { carouselViewModel } = viewModel;
    const carousel = (
      <div className={styles.carouselContainer}>
        <Carousel
          viewModel={carouselViewModel}
          className={styles.ytVideo}
        />
        <div className={styles.bgContainer}>
          <img src={viewModel.picturebg} alt="bg" />
        </div>
      </div>
    );
    const _renderPreBtn = (
      <>
        {
          carouselViewModel.slides.length !== 1
            ? (
              <div
                className={clsx(styles.preBtnContainer, viewModel.activeIndex === 0 && styles.disabled)}
                onClick={() => carouselViewModel.carousel.slidePrev()}
              >
                <div className={clsx(styles.preBtn)} />
              </div>
            )
            : null
        }
      </>
    );
    const _renderNextBtn = (
      <>
        {
          carouselViewModel.slides.length !== 1
            ? (
              <div
                className={clsx(styles.nextBtnContainer, viewModel.isLastIndex && styles.disabled)}
                onClick={() => carouselViewModel.carousel.slideNext()}
              >
                <div className={clsx(styles.nextBtn)} />
              </div>
            )
            : null
        }
      </>
    );
    return (
      <div className={styles.sectionContainer}>
        <Pxcode
          slogan={viewModel.slogan}
          labeleg={viewModel.labeleg}
          label={viewModel.label}
          labelct={viewModel.labelct}
          title={viewModel.title}
          description={viewModel.description}
          carousel={carousel}
          _renderPreBtn={_renderPreBtn}
          _renderNextBtn={_renderNextBtn}
        />
      </div>
    );
  }
}

SectionB1.defaultProps = {
  viewModel: {}
};

SectionB1.propTypes = {
  viewModel: PropTypes.object
};

export default SectionB1;
