import React from 'react';
import { Helmet } from 'react-helmet';

// import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import data from 'src/json/other.json';
import injectData from 'src/json/inject.json';

const AppLabel = () => {
  // const { state } = useAppStateContext();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:title" content={`線上接待中心 | ${injectData.title}`} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="來maaūu，線上買好屋" />
      <meta property="og:image" content={data.order.finish} />
      <meta property="og:url" content={`https://${window.location.href}`} />
      <title>{injectData.title || ''}</title>
    </Helmet>
  );
};

export default AppLabel;
