import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import dayjs from 'dayjs';
import isMobile from 'ismobilejs';
import duration from 'dayjs/plugin/duration';
import 'antd/dist/antd.css';

import { AppStateProvider } from 'src/components/AppStateProvider';
import { some } from 'src/utils';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.extend(duration);

configure({
  enforceActions: 'always'
});

const rootElement = document.getElementById('root');
const width = window.innerWidth;

// ! Note: must `hydrate` a single root dom element, and must have an entity, for example, it cannot be a provider
if (
  some(
    window.location.pathname,
    [
      /^\/$/
    ]
  )
  && !isMobile(window.navigator).any
  && width >= 768
  && rootElement.hasChildNodes()
) {
  ReactDOM.hydrate(
    (
      <div className="wrapper">
        <AppStateProvider>
          <App />
        </AppStateProvider>
      </div>
    ),
    rootElement
  );
} else {
  ReactDOM.render(
    (
      <div className="wrapper">
        <AppStateProvider>
          <App />
        </AppStateProvider>
      </div>
    ),
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
