import Label from './Label';

export default class Area {
  constructor(params) {
    const {
      id,
      name,
      prefix,
      labels
    } = params ?? {};

    this.id = id;
    this.name = name;
    this.prefix = prefix;
    this.labels = labels?.map((label) => Label.fromRes(label)) ?? [];
  }

  static fromRes(data) {
    return new Area(data);
  }
}

