import React, { forwardRef, useImperativeHandle } from 'react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Image(props, ref, isLazy) {
  const { value } = props;

  useImperativeHandle(ref, () => ({
    onEnter() { },
    onLeave() { }
  }));

  return (
    <LazyLoadImage
      key={`${value}`}
      alt=""
      height="100%"
      width="100%"
      src={value}
      wrapperClassName={cn('loading_placeHolder_swiper', 'swiper-lazy')}
    />
  );
}


export default forwardRef(Image);
