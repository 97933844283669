import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import Model from './Model';

@observer
class PopupPicText extends React.Component {
  constructor(props) {
    super(props);
    this.model = new Model(props.viewModel);
  }
  render() {
    return (
      <div className={styles.popupContainer}>
        <div className={styles.imgContainer}>
          <img className={styles.image} src={this.model.image} alt="building" />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {this.model.title}
          </div>
          <div className={styles.description}>
            {this.model.description}
          </div>
        </div>
      </div>
    );
  }
}

PopupPicText.propTypes = {
  viewModel: PropTypes.object
};

PopupPicText.defaultProps = {
  viewModel: null
};

export default PopupPicText;
