import is from 'is_js';

export default class OrdererInfo {
  constructor(params) {
    const {
      firstName,
      lastName,
      gender,

      year,
      month,
      day,

      city,
      region,
      postalCode,
      address,

      job,
      email,
      telephoneRequired,
      telephoneOptional,

      telephone1,
      telephone2
    } = params ?? {};

    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;

    this.year = year;
    this.month = month;
    this.day = day;

    this.city = city;
    this.region = region;
    this.postalCode = postalCode;
    this.address = address;

    this.job = job;
    this.email = email;
    this.telephoneRequired = telephoneRequired;
    this.telephoneOptional = telephoneOptional;

    this.telephone1 = telephone1;
    this.telephone2 = telephone2;
  }

  get fullname() {
    if (!this.lastName && !this.firstName) {
      return null;
    }

    // is english
    if (/[a-zA-Z0-9]/.test(this.lastName) || /[a-zA-Z0-9]/.test(this.firstName)) {
      return `${this.firstName} ${this.lastName}`;
    }

    return `${this.lastName}${this.firstName}`;
  }

  toFields = () => {
    const y = is.existy(this.year) ? `0000${this.year}`.slice(-4) : null;
    const m = is.existy(this.month) ? `0${this.month}`.slice(-2) : null;
    const d = is.existy(this.day) ? `0${this.day}`.slice(-2) : null;

    return [
      { key: 'name', label: '訂購人', value: this.fullname ?? '' },
      { key: 'telephone', label: '電話號碼', value: [this.telephoneRequired, this.telephoneOptional].filter((t) => !!t).join(', ') },
      { key: 'email', label: '電子郵件', value: this.email ?? '' },
      {
        key: 'birthday',
        label: '生日',
        value: [y, m, d].filter((el) => is.existy(el)).join('/')
      },
      {
        key: 'address',
        label: '聯絡地址',
        value: [this.postalCode, this.city, this.region, this.address].filter((el) => is.existy(el)).join(' ')
      }
      // { key: 'gender', label: '性別', value: this.gender },
      // { key: 'job', label: '職業', value: this.job }
    ]; // .filter((el) => is.existy(el.value) && el.value !== '');
  };

  static fromJSON(json) {
    try {
      return new OrdererInfo(JSON.parse(json));
    } catch (err) {
      return new OrdererInfo();
    }
  }
}
