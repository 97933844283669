/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';

export default function Promo_002_final(props) {
  return (
    <div
      className={`promo-002-final ${css(styles.group, styles.group_layout)}`}
    >
      <Px.div
        x="41px 1838fr 41px"
        y="minmax(55px, 75fr) minmax(0px, max-content) 75fr"
        className={css(styles.flex)}
        xxlX="20px 1838fr 20px"
        xxlY="minmax(55px, 45fr) minmax(0px, max-content) 45fr"
        xlX="11px 1838fr 11px"
        xlY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
        lgX="10px 1838fr 10px"
        lgY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
      >
        <div className={css(styles.flex_row)}>
          <h2 className={css(styles.slogan, styles.slogan_layout)}>
            {props.slogan}
          </h2>
        </div>
        <div className={css(styles.flex1, styles.flex1_layout)}>
          <div className={css(styles.flex1_col)}>
            <div className={css(styles.picture, styles.picture_layout)}>
              {props.carousel}
              {props._renderPreBtn}
              {props._renderNextBtn}
            </div>
          </div>
          <div className={css(styles.flex1_spacer)} />
          <div className={css(styles.flex1_col1)}>
            <Px.div
              x="0px 412fr 0px"
              y="259fr minmax(0px, max-content) 425fr"
              className={css(styles.flex)}
              xsX="0px 412fr 0px"
              xsY="130fr minmax(0px, max-content) 213fr"
            >
              <div className={css(styles.group1, styles.group1_layout)}>
                <div className={css(styles.labelBox)}>
                  <div className={css(styles.labeleg, styles.labeleg_layout)}>
                    {props.labeleg}
                  </div>
                  <div
                    style={{ '--color': props.label }}
                    className={css(styles.label, styles.label_layout)}
                  />
                </div>
              </div>

              <h3
                className={css(styles.labelct_box, styles.labelct_box_layout)}
              >
                <pre className={css(styles.labelct)}>{props.labelct}</pre>
              </h3>
              <h1 className={css(styles.title, styles.title_layout)}>
                {props.title}
              </h1>
              <h5
                className={css(
                  styles.description_box,
                  styles.description_box_layout
                )}
              >
                <pre className={css(styles.description)}>
                  {props.description}
                </pre>
              </h5>
            </Px.div>
          </div>
          <div className={css(styles.flex1_spacer1)} />
        </div>
      </Px.div>
    </div>
  );
}

Promo_002_final.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)',
    width: '100%',
    height: '100%'
  },
  group_layout: {
    position: 'relative',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  flex_row: {
    display: 'flex'
  },
  slogan: {
    display: 'flex',
    justifyContent: 'center',
    font: '100 24px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '20px',
      textAlign: 'center'
    }
  },
  slogan_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: '0px auto 5px',
    '@media (max-width: 991.98px)': {
      width: 'fit-content',
      margin: '0px auto 5px'
    },
    '@media (max-width: 767.98px)': {
      width: 'fit-content',
      margin: '0px auto 5px'
    },
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '0px auto 5px'
    }
  },
  flex1: {
    display: 'flex',
    '@media (max-width: 991.98px)': {
      flexWrap: 'wrap'
    }
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '58px 0px 0px',
    '@media (max-width: 1919.98px)': {
      margin: '25px 0px'
    },
    '@media (max-width: 1399.98px)': {
      margin: '25px 0px'
    },
    '@media (max-width: 1199.98px)': {
      margin: '10px 0px'
    }
  },
  flex1_col: {
    display: 'flex',
    flex: '0 1 950px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 55.55555555555556%'
    }
  },
  picture: {
    width: '100%',
    paddingTop: '73.33%'
  },
  picture_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  flex1_spacer: {
    display: 'flex',
    flex: '0 2 173px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 5.555555555555555%'
    }
  },
  flex1_col1: {
    display: 'flex',
    flex: '1 1 412px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 33.33333333333333%'
    }
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    minHeight: 14,
    margin: 0
  },
  labelBox: {
    position: 'relative'
  },
  label: {
    backgroundColor: 'var(--color)',
    width: '100%',
    height: '100%'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: 1,
    left: 0,
    zIndex: 0
  },
  labeleg: {
    font: '900 12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px'
  },
  labeleg_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0,
    zIndex: 1
  },
  labelct: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 20px/1 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 991.98px)': {
      fontSize: '14px',
      textAlign: 'left'
    }
  },
  labelct_box: {
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labelct_box_layout: {
    position: 'relative',
    margin: '1px 0px 0px',
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px'
    }
  },
  title: {
    font: '900 32px/1.375 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '25px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '20px',
      textAlign: 'left'
    },
    '@media (max-width: 767.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '27px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 0px 0px',
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px'
    }
  },
  description: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '16px/1.875 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap'
  },
  description_box: {},
  description_box_layout: {
    position: 'relative',
    margin: '11px 0px 0px',
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px'
    }
  },
  flex1_spacer1: {
    display: 'flex',
    flex: '1 1 173px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 5.555555555555555%'
    }
  }
});

Promo_002_final.propTypes = {
  slogan: PropTypes.string,
  carousel: PropTypes.element,
  _renderNextBtn: PropTypes.element,
  _renderPreBtn: PropTypes.element,
  label: PropTypes.string,
  labeleg: PropTypes.string,
  labelct: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};

Promo_002_final.defaultProps = {
  slogan: null,
  carousel: null,
  _renderNextBtn: null,
  _renderPreBtn: null,
  label: null,
  labeleg: null,
  labelct: null,
  title: null,
  description: null
};
