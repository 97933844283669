export default class Trade {
  constructor(params) {
    const {
      id,
      transactionId,
      tradeNo,
      status,
      message,
      updatedAt,
      createdAt
    } = params;

    this.id = id;
    this.transactionId = transactionId;
    this.tradeNo = tradeNo;
    /**
     * @status {string}
     * '0' 交易訂單成立未付款
     * '1' 交易訂單成立已付款
     * '10200095' 時，代表交易訂單未成立，
     * 消費者未完成付款作業，故交易失敗。
     */
    this.status = status;
    this.message = message;

    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  static fromRes(data) {
    return new Trade(data);
  }
}
