import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import { Theme } from 'src/components/Modal/themes';
import UserService from 'src/services/user';
import ReceiptV2Page from 'src/pages/ReceiptV2Page';
import { delay } from 'src/utils';
import flexIcon from 'src/assets/icon_listed.svg';
import flexIcon1 from 'src/assets/icon_list.svg';
import gridIcon from 'src/assets/icon_img.svg';
import gridIcon1 from 'src/assets/icon_imged.svg';

import Model, { Status } from './ShoppingItem/model';
import ShoppingItem from './ShoppingItem';
import styles from './styles.module.css';

export const Identity = {
  consumer: 'consumer',
  sales: 'sales'
};

export default function ShoppingList({
  buildings,
  identity,
  orders,
  onDelete
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [nowDownLoadingID, setID] = useState(0);
  const [displayType, setDisplay] = useState(1); // 0: flex, 1: grid

  const { state, actions } = useAppStateContext();

  const downloadReceipt = async (ev, oid) => {
    try {
      if (loading) {
        return;
      } // ! Note: must before `stopPropagation`

      ev.stopPropagation();
      setLoading(true);
      setID(oid);

      const receipt = await UserService.getOrderReceipt(state.profile.id, oid);

      const doc = (
        <ReceiptV2Page
          plan={state.plan}
          receipt={receipt}
          profile={state.profile}
        />
      );
      const asPdf = pdf([]); // [] is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      // pdf title
      const pdfTitle = `${state.plan.name ?? 'recepit'}_${receipt.trade?.transactionId}`;

      await Promise.all([
        saveAs(blob, pdfTitle),
        delay(2000) // prevent double click
      ]);
    } catch (err) {
      console.log('downloadReceipt', err);
    } finally {
      setLoading(false);
      setID(0);
    }
  };

  const deleteOrder = (evt, id) => {
    evt.stopPropagation();
    actions.showModal({
      theme: Theme.warning,
      title: 'INFO',
      content: '您即將刪除此筆資料\n請確認是否執行',
      cancelText: '先不要',
      okText: '刪除',
      onOk: async () => {
        await onDelete(id);
      },
      onCancel: () => {}
    });
  };

  const createItem = (order) => {
    switch (order.orderType) {
      case Status.sold:
      case Status.pending:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            disabled={loading}
            onDownload={(ev) => downloadReceipt(ev, order.id)}
            nowDownLoadingID={nowDownLoadingID}
            onClick={() => {
              history.push(`/order/${order.id}`);
            }}
            displayType={displayType}
          />
        );
      case Status.notSold:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            disabled={loading}
            onDownload={(ev) => downloadReceipt(ev, order.id)}
            nowDownLoadingID={nowDownLoadingID}
            onClick={() => {
              if (identity === Identity.sales) {
                history.push(`/order/${order.id}`);
              }
            }}
            displayType={displayType}
          />
        );
      case Status.abandon:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            disabled={loading}
            onDownload={(ev) => downloadReceipt(ev, order.id)}
            nowDownLoadingID={nowDownLoadingID}
            // onDelete={(e) => {
            //   deleteOrder(e, order.id);
            // }}
            onClick={() => {
              if (identity === Identity.sales) {
                history.push(`/order/${order.id}`);
              }
            }}
            displayType={displayType}
          />
        );
      case Status.selecting:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            onDelete={(e) => {
              deleteOrder(e, order.id);
            }}
            onClick={() => {
              history.push(`/pickout?status=building&building=${order.door.floor.building.id}&floor=${order.door.floor.id}&door=${order.door.id}${order.garagesUrl}${order.carSpaceUrl}${order.designUrl}`);
            }}
            displayType={displayType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={displayType ? styles.wrapper_grid : styles.wrapper_flex}>


      <div className={styles.header}>
        <div className={styles.header_text}>{`我的願望清單 (${orders.length})`}</div>

        <div className={styles.switch}>
          <div
            onClick={() => {
              setDisplay(0);
            }}
          >
            <img
              src={displayType === 0 ? flexIcon1 : flexIcon}
              style={{ width: 20, height: 20, marginRight: 10 }}
              alt="flex"
            />
          </div>

          <div
            onClick={() => {
              setDisplay(1);
            }}
          >
            <img
              src={displayType === 1 ? gridIcon1 : gridIcon}
              style={{ width: 20, height: 20 }}
              alt="grid"
            />
          </div>
        </div>
      </div>

      {orders.map((order) => createItem(Model.fromRes(order, buildings)))}
    </div>
  );
}

ShoppingList.defaultProps = {
  orders: [],
  identity: 'consumer',
  onDelete: () => {},
  buildings: []
};

ShoppingList.propTypes = {
  orders: PropTypes.array,
  identity: PropTypes.string,
  onDelete: PropTypes.func,
  buildings: PropTypes.array
};
