import Order from './Order';
import Trade from './Trade';

export default class Receipt {
  constructor(params) {
    const {
      order,
      trade
    } = params;

    this.order = Order.fromRes(order);
    this.trade = Trade.fromRes(trade);
  }

  static fromRes(data) {
    return new Receipt(data);
  }
}
