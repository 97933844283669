import Garage from './Garage';

export default class Slot {
  constructor(params) {
    const {
      id,
      isOptional,
      garages
    } = params ?? {};

    this.id = id;
    this.isOptional = isOptional;
    this.garages = garages?.map((garage) => Garage.fromRes(garage)) ?? [];
  }

  static fromRes(data) {
    return new Slot(data);
  }
}

