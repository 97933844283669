import { observable, action, computed, makeObservable } from 'mobx';
import React from 'react';

export default class CustomizeCarouselViewModel {
  @observable slides = [];
  @observable activeIndex = 0;
  @observable carousel = null;
  @observable counter = 0;

  constructor(slides) {
    makeObservable(this);

    this.deserialize(slides);
  }

  @action
  deserialize = (slides = []) => {
    this.slides = slides.map((slide) => {
      this.counter += 1;
      return ({
        ...slide,
        id: this.counter
      });
    });
    this.slideIndex = this.slides.length;
  }

  @action
  onClick = (index) => {
    this.openNews(index);
  }

  @action
  openNews = (index) => {
    const targetPath = this.slides[index].path;
    if (!targetPath.trim()) {
      return;
    }
    window.open(targetPath, '_blank');
  }

  @action
  wrapTo = (index) => {
    this.carousel.slideTo(index);
  }

  @action
  onSlideChange = (swiper) => {
    this.activeIndex = swiper.realIndex;
    swiper.updateSize();
  }

  @action
  onSwiper = (swiper) => {
    this.carousel = swiper;
  };
}

