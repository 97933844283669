import { observable, makeObservable, action } from 'mobx';

export default class CustomizeModalViewModel {
  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  @action
  hideModal = () => {
    this.isShow = false;
  }

  @action
  showModal = () => {
    this.isShow = true;
  }
}
