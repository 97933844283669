import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Carousel from '../../Carousel';

import PxCode from './P007';

import styles from './styles.module.css';

@observer
class SectionD extends React.Component {
  render() {
    const { viewModel } = this.props;
    const { carouselViewModel } = viewModel;
    const carousel = (
      <div className={styles.carouselContainer}>
        <Carousel
          viewModel={carouselViewModel}
          customSlide={styles.customSlide}
          cssMode
        />
      </div>
    );
    return (
      <PxCode
        public1={viewModel.public1}
        public2={viewModel.public2}
        icon1={viewModel.icon1}
        icon2={viewModel.icon2}
        icon3={viewModel.icon3}
        info1={viewModel.info1}
        info2={viewModel.info2}
        info3={viewModel.info3}
        carousel={carousel}
      />
    );
  }
}

SectionD.propTypes = {
  viewModel: PropTypes.object
};

SectionD.defaultProps = {
  viewModel: null
};

export default SectionD;
