import { observable, makeObservable, action } from 'mobx';
import React from 'react';

export default class CarouselViewModel {
  @observable activeIndex = 0;
  @observable slides = [];
  slideRefs = [];
  carousel = null;

  constructor(slides) {
    makeObservable(this);
    this.deserialize(slides);
  }

  @action deserialize = (slides = []) => {
    this.slides = slides;
    this.slides.forEach(() => {
      this.slideRefs.push(React.createRef());
    });
  }

  @action onSlideChange = (swiper) => {
    const previousRef = this.slideRefs[this.activeIndex]?.current;
    const currentRef = this.slideRefs[swiper.activeIndex]?.current;
    this.activeIndex = swiper.activeIndex;
    previousRef?.onLeave();
    currentRef?.onEnter();
    // force renderBullet re-render
    swiper.pagination.render();
  }

  @action updateSlides = (slides) => {
    this.activeIndex = 0;
    this.carousel?.slideTo(this.activeIndex);
    this.slides = slides;
    this.slideRefs = [];
    this.slides.forEach(() => {
      this.slideRefs.push(React.createRef());
    });

    // WorkAround: Avoiding get null ref when accessing ref immediately after slidesRefs reset.
    setTimeout(this.onEnter, 200);
  }

  @action
  pickoutUpdateSlide = (slides) => {
    this.activeIndex = 0;
    this.slides = slides;
    this.slideRefs = [];
    this.slides.forEach(() => {
      this.slideRefs.push(React.createRef());
    });

    // WorkAround: Avoiding get null ref when accessing ref immediately after slidesRefs reset.
    setTimeout(this.onEnter, 200);
  }

  @action setActiveIndex = (index = 0) => {
    this.activeIndex = index;
  }

  @action
  reload = () => {
    this.carousel.slideTo(0);
    this.activeIndex = 0;

    this.carousel.pagination.render();
  }

  onEnter = () => {
    const currentRef = this.slideRefs[this.activeIndex]?.current;
    currentRef?.onEnter?.();
  }

  onLeave = () => {
    const currentRef = this.slideRefs[this.activeIndex]?.current;
    currentRef?.onLeave?.();
  }

  onSwiper = (swiper) => {
    this.carousel = swiper;
  };
}
