import React, { Component } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import './customizeSwiper.css';

@observer
class Carousel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel } = this.props;
    const isMobile = this.props.device === 'mobile';

    return (
      <Swiper
        onSwiper={viewModel.onSwiper}
        onSlideChange={viewModel.onSlideChange}
        loop
        slidesPerView="auto"
        className={styles.container}
        resizeObserver
      >
        {viewModel.slides.map((el, index) => (
          <SwiperSlide
            key={el.id}
            className={clsx(styles.slide, isMobile ? styles.mobileSlide : styles.webSlide)}
            onClick={() => viewModel.onClick(index)}
          >
            <img
              src={el.value}
              className={styles.img}
              alt="newsPic"
            />
            <div className={clsx(isMobile ? styles.mobileText : styles.text)}>{el.title}</div>
            {
              isMobile
                ? null
                : (
                  <div className={styles.more}>
                    READ
                  </div>
                )
            }
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
}

Carousel.defaultProps = {
  viewModel: {},
  device: 'web'
};

Carousel.propTypes = {
  viewModel: PropTypes.object,
  device: PropTypes.string
};

export default Carousel;
