/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';

export default function Promo_006(props) {
  return (
    <div className={`promo-006 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="40px 1840fr 40px"
        y="minmax(55px, 90fr) minmax(0px, max-content) 90fr"
        className={css(styles.flex)}
        xxlX="20px 1840fr 20px"
        xxlY="minmax(55px, 45fr) minmax(0px, max-content) 45fr"
        xlX="10px 1840fr 10px"
        xlY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
      >
        <div className={css(styles.flex_row)}>
          <h2 className={css(styles.slogan, styles.slogan_layout)}>
            {props.slogan}
          </h2>
        </div>

        <div className={css(styles.flex1, styles.flex1_layout)}>
          <div className={css(styles.flex1_col)}>
            <div className={css(styles.picture, styles.picture_layout)}>
              {props.carousel}
              {props._renderPreBtn}
              {props._renderNextBtn}
            </div>
          </div>
          <div className={css(styles.flex1_spacer)} />
          <div className={css(styles.flex1_col1)}>
            <Px.div
              x="0px 554fr 0px"
              y="279fr minmax(0px, max-content) 265fr"
              className={css(styles.flex)}
              xsX="0px 554fr 0px"
              xsY="140fr minmax(0px, max-content) 133fr"
            >
              <div className={css(styles.group1, styles.group1_layout)}>
                <div className={css(styles.labelBox)}>
                  <div className={css(styles.labeleg, styles.labeleg_layout)}>
                    {props.labeleg}
                  </div>
                  <div
                    style={{ '--color': props.label }}
                    className={css(styles.label, styles.label_layout)}
                  />
                </div>
              </div>

              <Px.h3
                x="1fr 554fr 0px"
                y="1px minmax(0px, 20fr) 0px"
                className={css(styles.labelct_box)}
                xsX="0fr 554fr 0px"
                xsY="25px minmax(0px, 20fr) 0px"
              >
                <pre className={css(styles.labelct)}>{props.labelct}</pre>
              </Px.h3>
              <h1 className={css(styles.title, styles.title_layout)}>
                {props.title}
              </h1>
              <h5
                className={css(styles.description, styles.description_layout)}
              >
                {props.description}
              </h5>

              <div className={css(styles.flex3, styles.flex3_layout)}>
                <div className={css(styles.flex3_col)}>
                  <div
                    className={css(
                      styles.paragraph_body_wrap,
                      styles.paragraph_body_wrap_layout
                    )}
                  >
                    <Px.div
                      style={{ '--src': `url(${props.matIcon1}` }}
                      x="0px 70fr 110fr"
                      y="0px minmax(0px, 70fr) 0px"
                      absolute
                      className={css(styles.mat_icon1)}
                    />
                    <Px
                      x="70fr 110fr 0px"
                      y="20fr minmax(0px, max-content) 20fr"
                      className={css(styles.mat1_box)}
                    >
                      <pre className={css(styles.mat1)}>{props.mat1}</pre>
                    </Px>
                  </div>
                </div>
                <div className={css(styles.flex3_spacer)} />
                <div className={css(styles.flex3_col1)}>
                  <div
                    className={css(
                      styles.small_paragraph_body_wrap,
                      styles.small_paragraph_body_wrap_layout
                    )}
                  >
                    <Px.div
                      style={{ '--src': `url(${props.matIcon2}` }}
                      x="0px 70fr 110fr"
                      y="0px minmax(0px, 70fr) 0px"
                      absolute
                      className={css(styles.mat_icon2)}
                    />
                    <Px
                      x="70fr 110fr 0px"
                      y="21fr minmax(0px, max-content) 21fr"
                      className={css(styles.mat2_box)}
                    >
                      <pre className={css(styles.mat2)}>{props.mat2}</pre>
                    </Px>
                  </div>
                </div>
                <div className={css(styles.flex3_spacer)} />
                <div className={css(styles.flex3_col2)}>
                  <Px.div
                    x="0px 153fr 0px"
                    y="0 1fr 0"
                    className={css(styles.small_paragraph_body_wrap1)}
                  >
                    <Px.div
                      style={{ '--src': `url(${props.matIcon3}` }}
                      x="0px 70fr 110fr"
                      y="0px minmax(0px, 70fr) 0px"
                      absolute
                      className={css(styles.mat_icon3)}
                    />
                    <Px
                      x="70fr 110fr 0px"
                      y="21fr minmax(0px, max-content) 21fr"
                      className={css(styles.mat3_box)}
                    >
                      <pre className={css(styles.mat3)}>{props.mat3}</pre>
                    </Px>
                  </Px.div>
                </div>
              </div>
            </Px.div>
          </div>
          <div className={css(styles.flex1_spacer)} />
        </div>
      </Px.div>
    </div>
  );
}

Promo_006.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)',
    height: '100%',
    width: '100%'
  },
  group_layout: {
    position: 'relative',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  flex_row: {
    display: 'flex'
  },
  slogan: {
    display: 'flex',
    justifyContent: 'flex-end',
    font: '100 24px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'right',
    letterSpacing: '1px',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      fontSize: '21px',
      textAlign: 'right'
    }
  },
  slogan_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: '0px auto',
    '@media (max-width: 991.98px)': {
      width: 'fit-content',
      margin: '0px auto'
    },
    '@media (max-width: 767.98px)': {
      width: 'fit-content',
      margin: '0px auto'
    },
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '0px auto'
    }
  },
  flex1: {
    display: 'grid',
    gridTemplateColumns: '55% 3fr 30% 3fr'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '28px 0px 5px'
  },
  flex1_col: {
    display: 'flex',
    flex: '1 1 1040px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 calc(66.66666666666666% - 20px)'
    }
  },
  picture: {
    width: '100%',
    paddingTop: '73.33%'
  },
  picture_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  flex1_spacer: {
    display: 'flex',
    flex: '0 2 120px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 20px'
    }
  },
  flex1_col1: {
    display: 'flex',
    flex: '1 1 412px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 calc(33.33333333333333% - 20px)'
    }
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    overflow: 'visible',
    minHeight: 14
  },
  labelBox: {
    position: 'relative'
  },
  label: {
    backgroundColor: 'var(--color)'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: 0,
    left: 1,
    width: '100%',
    zIndex: 0
  },
  labeleg: {
    font: '900 12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px'
  },
  labeleg_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0,
    zIndex: 1
  },
  labelct: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 20px/1 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 991.98px)': {
      fontSize: '14px',
      textAlign: 'left'
    }
  },
  labelct_box: {
    width: '100%',
    height: '100%',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  title: {
    font: '900 30px/1.4666666666666666 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '25px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '20px',
      textAlign: 'left'
    },
    '@media (max-width: 767.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '25px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 0px 0px',
    '@media (max-width: 991.98px)': {
      margin: 0
    },
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px'
    }
  },
  description: {
    font: '16px/1.875 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    margin: 0,
    whiteSpace: 'pre-wrap'
  },
  description_layout: {
    position: 'relative',
    margin: '11px 90px 0px 0px',
    '@media (max-width: 1399.98px)': {
      margin: '11px 50px 0px 0px'
    },
    '@media (max-width: 1199.98px)': {
      margin: '11px 30px 0px 0px'
    },
    '@media (max-width: 991.98px)': {
      margin: '11px 30px 0px 0px'
    },
    '@media (max-width: 575.98px)': {
      margin: '25px 45px 0px 0px'
    }
  },
  flex3: {
    display: 'flex',
    '@media (max-width: 1199.98px)': {
      flexWrap: 'wrap'
    }
  },
  flex3_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '40px 0px 0px 1px',
    '@media (max-width: 1199.98px)': {
      margin: '20px 100px 0px 1px'
    },
    '@media (max-width: 991.98px)': {
      margin: '10px 100px 0px 1px'
    },
    '@media (max-width: 575.98px)': {
      margin: '25px 100px 0px 10px'
    }
  },
  flex3_col: {
    display: 'flex',
    flex: '1 1 180px',
    '@media (max-width: 1199.98px)': {
      flex: '0 0 100%'
    },
    '@media (max-width: 991.98px)': {
      flex: '0 0 180px'
    }
  },
  paragraph_body_wrap: {
    display: 'flex'
  },
  paragraph_body_wrap_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  mat_icon1: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  mat1: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1.0833333730697632px',
    whiteSpace: 'pre-wrap'
  },
  mat1_box: {
    width: '100%',
    height: '100%'
  },
  flex3_spacer: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 10,
    '@media (max-width: 1199.98px)': {
      flex: '0 0 100%',
      minWidth: 'unset',
      minHeight: 20
    }
  },
  flex3_col1: {
    display: 'flex',
    flex: '1 1 180px',
    '@media (max-width: 1199.98px)': {
      flex: '0 0 100%'
    },
    '@media (max-width: 991.98px)': {
      flex: '0 0 166px'
    }
  },
  small_paragraph_body_wrap: {
    display: 'flex'
  },
  small_paragraph_body_wrap_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  mat_icon2: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  mat2: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0.8571428656578064px',
    whiteSpace: 'pre-wrap'
  },
  mat2_box: {
    width: '100%',
    height: '100%'
  },
  flex3_col2: {
    display: 'flex',
    flex: '1 1 180px',
    '@media (max-width: 1199.98px)': {
      flex: '0 0 100%'
    },
    '@media (max-width: 991.98px)': {
      flex: '0 0 153px'
    }
  },
  small_paragraph_body_wrap1: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  mat_icon3: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  mat3: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0.8571428656578064px',
    whiteSpace: 'pre-wrap'
  },
  mat3_box: {
    width: '100%',
    height: '100%'
  },
  flex1_spacer1: {
    display: 'flex',
    flex: '1 2 20px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 20px'
    }
  }
});

Promo_006.propTypes = {
  slogan: PropTypes.string,
  carousel: PropTypes.element,
  _renderNextBtn: PropTypes.element,
  _renderPreBtn: PropTypes.element,
  label: PropTypes.string,
  labelct: PropTypes.string,
  labeleg: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  mat1: PropTypes.string,
  mat2: PropTypes.string,
  mat3: PropTypes.string,
  matIcon1: PropTypes.string,
  matIcon2: PropTypes.string,
  matIcon3: PropTypes.string
};

Promo_006.defaultProps = {
  slogan: null,
  carousel: null,
  _renderNextBtn: null,
  _renderPreBtn: null,
  label: null,
  labelct: null,
  labeleg: null,
  title: null,
  description: null,
  mat1: null,
  mat2: null,
  mat3: null,
  matIcon1: null,
  matIcon2: null,
  matIcon3: null
};
