/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import React from 'react';
import Iframe from 'src/components/Iframe';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px } from '../../posize';
import Icon from './Icon';

function renderMap(props) {
  return (
    <div className={css(mapStyles.map, mapStyles.map_layout)}>
      <div className={css(mapStyles.image, mapStyles.image_layout)}>
        <Iframe value={props.map} isGrayScale />
      </div>
    </div>
  );
}

export default function Promo_012(props) {
  return (
    <div className={`promo-012 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="0px 1920fr 0px"
        y="1fr minmax(0px, max-content) 1fr"
        className={css(styles.flex)}
        xxlX="0px 1920fr 0px"
        xxlY="25fr minmax(0px, max-content) 25fr"
      >
        <div className={css(styles.flex1, styles.flex1_layout)}>
          <div className={css(styles.flex1_col)}>
            <div className={css(styles.component, styles.component_layout)}>
              <Icon
                info_icon={props.icon1}
                info={props.info1}
              />
            </div>
          </div>
          <div className={css(styles.flex1_spacer)} />
          <div className={css(styles.flex1_col)}>
            <div className={css(styles.component, styles.component_layout)}>
              <Icon
                info_icon={props.icon2}
                info={props.info2}
              />
            </div>
          </div>
          <div className={css(styles.flex1_spacer1)} />
          <div className={css(styles.flex1_col)}>
            <div className={css(styles.component, styles.component_layout)}>
              <Icon
                info_icon={props.icon3}
                info={props.info3}
              />
            </div>
          </div>
        </div>

        <div className={css(styles.flex2, styles.flex2_layout)}>
          <div className={css(styles.flex2_col)}>
            <div className={css(styles.flex3, styles.flex3_layout)}>
              <h1 className={css(styles.infocase, styles.infocase_layout)}>
                {props.infoCase}
              </h1>

              <div className={css(styles.flex4, styles.flex4_layout)}>
                <h4 className={css(styles.info, styles.info_layout)}>
                  {props.info}
                </h4>
                <div
                  className={css(
                    styles.infoeng_box,
                    styles.infoeng_box_layout
                  )}
                >
                  <div className={css(styles.infoeng_span0)}>{props.infoeng}</div>
                  <div className={css(styles.infoeng_span1)}>/</div>
                </div>
              </div>

              <div className={css(styles.line, styles.line_layout)} />

              <div className={css(styles.flex5, styles.flex5_layout)}>
                <div className={css(styles.flex5_col)}>
                  <div
                    className={css(
                      styles.infosubtit1_box,
                      styles.infosubtit1_box_layout
                    )}
                  >
                    <pre className={css(styles.infosubtit1)}>
                      {props.infoSubTit1}
                    </pre>
                  </div>
                </div>
                <div className={css(styles.flex5_spacer)} />
                <div
                  className={css(
                    styles.infosubtit2_box,
                    styles.infosubtit2_box_layout
                  )}
                >
                  <pre className={css(styles.infosubtit2)}>
                    {props.infoSubTit2}
                  </pre>
                </div>
              </div>
            </div>
          </div>
          <div className={css(styles.flex2_spacer)} />
          <div className={css(styles.flex2_col1)}>
            <Px.div
              x="0px 800fr 0px"
              y="2fr minmax(0px, max-content) 12px"
              className={css(styles.flex)}
              smX="0px 800fr 0px"
              smY="25fr minmax(0px, max-content) 25px"
            >
              {renderMap(props)}
            </Px.div>
          </div>
        </div>
      </Px.div>
    </div>
  );
}

Promo_012.inStorybook = true;

const mapStyles = StyleSheet.create({
  map_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: 0
  },
  map: {
    display: 'flex',
    position: 'relative'
  },
  image: {
    width: '100%',
    height: '500px',
    position: 'absolute'
  },
  image_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  }
});

const shoppingbuttonStyles = StyleSheet.create({
  shoppingbutton_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: '12px 26.5px 12px 36.5px'
  },
  shoppingbutton: {
    display: 'flex',
    alignItems: 'center'
  },
  shoppingbutton_col: {
    display: 'flex',
    flex: '0 1 184px'
  },
  highlights: {
    display: 'flex',
    justifyContent: 'center',
    font: '18px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    letterSpacing: '5.625px',
    lineHeight: '26px'
  },
  highlights_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  shoppingbutton_spacer: {
    display: 'flex',
    flex: '0 1 0.5px'
  },
  img: {
    background: 'var(--src) center center / contain no-repeat'
  },
  img_layout: {
    position: 'relative',
    flex: '0 1 52.5px',
    height: 8.68,
    width: 52.5,
    minWidth: 52.5
  }
});

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'relative',
    paddingTop: '48px',
    flexGrow: 1
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  flex1: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '112px 418px 0px 420px',
    '@media (max-width: 1919.98px)': {
      margin: '56px 209px 0px 210px'
    },
    '@media (max-width: 1399.98px)': {
      margin: '28px 105px 0px'
    },
    '@media (max-width: 1199.98px)': {
      margin: '25px 53px 0px'
    },
    '@media (max-width: 991.98px)': {
      margin: '25px 27px 0px'
    },
    '@media (max-width: 767.98px)': {
      margin: '25px 14px 0px'
    },
    '@media (max-width: 575.98px)': {
      margin: '25px 10px 0px'
    }
  },
  flex1_col: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 207
  },
  component: {
    position: 'relative'
  },
  component_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  flex1_spacer: {
    display: 'flex',
    flex: '0 1 245px'
  },
  flex1_spacer1: {
    display: 'flex',
    flex: '0 1 246px'
  },
  flex2: {
    display: 'flex',
    '@media (max-width: 991.98px)': {
      flexWrap: 'wrap'
    }
  },
  flex2_layout: {
    position: 'relative',
    overflow: 'visible',
    width: '80.94%',
    minWidth: 'min-content',
    margin: '116px auto 50px',
    '@media (max-width: 1919.98px)': {
      width: '89.41%',
      minWidth: 'min-content',
      margin: '58px auto 50px'
    },
    '@media (max-width: 1399.98px)': {
      width: '94.41%',
      minWidth: 'min-content',
      margin: '29px auto 50px'
    },
    '@media (max-width: 1199.98px)': {
      width: '97.13%',
      minWidth: 'min-content',
      margin: '25px auto 50px'
    },
    '@media (max-width: 991.98px)': {
      width: '97.49%',
      minWidth: 'min-content',
      margin: '25px auto 0px'
    },
    '@media (max-width: 767.98px)': {
      width: '98.73%',
      minWidth: 'min-content',
      margin: '25px auto 0px'
    }
  },
  flex2_col: {
    display: 'flex',
    flex: '1 1 623px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 100%'
    }
  },
  flex3: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex3_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0,
    '@media (max-width: 991.98px)': {
      flexGrow: 1,
      margin: '0px 20px'
    }
  },
  infocase: {
    font: '60px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '5px',
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '53px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '50px',
      textAlign: 'left'
    },
    '@media (max-width: 767.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '46px',
      textAlign: 'left'
    }
  },
  infocase_layout: {
    position: 'relative',
    margin: 0
  },
  flex4: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  flex4_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '19px 0px 0px',
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px'
    }
  },
  info: {
    font: '18px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0.8181818127632141px'
  },
  info_layout: {
    position: 'relative'
  },
  infoeng: {
    fontFamily: '"Noto Sans TC", "Roboto", serif',
    color: 'rgb(0,0,0)',
    letterSpacing: '0px'
  },
  infoeng_box: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  infoeng_box_layout: {
    position: 'relative',
    marginLeft: '5px'
  },
  infoeng_span0: {
    font: '400 1em/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(120, 120, 120)',
    letterSpacing: '0.675000011920929px'
  },
  infoeng_span1: {
    font: '400 1em/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(120, 120, 120)',
    letterSpacing: '0.675000011920929px'
  },
  line: {
    backgroundColor: '#EBEBEB'
  },
  line_layout: {
    position: 'relative',
    height: 1,
    margin: '12px 23px 0px 0px',
    '@media (max-width: 575.98px)': {
      height: 1,
      margin: '25px 12px 0px 0px'
    }
  },
  flex5: {
    display: 'flex'
  },
  flex5_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '13px 0px 0px',
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px'
    }
  },
  flex5_col: {
    display: 'flex',
    flex: '1 1 273px'
  },
  infosubtit1: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '13px/2.8461538461538463 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap'
  },
  infosubtit1_box: {},
  infosubtit1_box_layout: {
    position: 'relative',
    width: 273,
    minWidth: 273,
    margin: '0px 22px 0px 0px',
    '@media (max-width: 2999.98px)': {
      width: 273,
      minWidth: 273,
      margin: 0
    }
  },
  flex5_spacer: {
    display: 'flex',
    flex: '0 2 55px'
  },
  infosubtit2: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '13px/2.8461538461538463 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap'
  },
  infosubtit2_box: {},
  infosubtit2_box_layout: {
    position: 'relative',
    flex: '1 1 273px',
    width: 193,
    minWidth: 193,
    margin: '0px 80px 0px 0px',
    '@media (max-width: 2999.98px)': {
      width: 193,
      minWidth: 193,
      margin: 0
    }
  },
  flex2_spacer: {
    display: 'flex',
    flex: '0 2 114px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 100%',
      minHeight: 20
    }
  },
  flex2_col1: {
    display: 'flex',
    flex: '1 1 800px',
    '@media (max-width: 991.98px)': {
      flex: '0 0 100%'
    },
    marginBottom: '40px'
  },
  cover_group: {
    display: 'flex',
    backgroundColor: 'rgb(41,41,41)',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.7
    }
  },
  img: {
    background: 'var(--src) center center / contain no-repeat'
  },
  img_layout: {
    position: 'relative',
    height: 20,
    margin: '105px 0px 0px',
    '@media (max-width: 1919.98px)': {
      height: 20,
      margin: '29px 0px 0px'
    },
    '@media (max-width: 1399.98px)': {
      height: 20,
      margin: '39px 0px 0px'
    },
    '@media (max-width: 1199.98px)': {
      height: 20,
      margin: '25px 0px 0px'
    }
  }
});

Promo_012.propTypes = {
  icon1: PropTypes.string,
  icon2: PropTypes.string,
  icon3: PropTypes.string,
  info1: PropTypes.string,
  info2: PropTypes.string,
  info3: PropTypes.string,
  infoCase: PropTypes.string,
  info: PropTypes.string,
  infoeng: PropTypes.string,
  infoSubTit1: PropTypes.string,
  infoSubTit2: PropTypes.string
};

Promo_012.defaultProps = {
  icon1: null,
  icon2: null,
  icon3: null,
  info1: null,
  info2: null,
  info3: null,
  infoCase: null,
  info: null,
  infoeng: null,
  infoSubTit1: null,
  infoSubTit2: null
};
