import { observable, action, makeObservable, computed } from 'mobx';

import { SectionType } from 'src/constants';
import CarouselViewModel from 'src/viewModels/Carousel';
import ISection from './ISection';

export default class SectionB1ViewModel extends ISection {
  @observable id;
  @observable slogan;
  @observable picture;
  @observable picturebg;
  @observable labeleg;
  @observable label;
  @observable labelct;
  @observable title;
  @observable description;
  @observable name;

  get type() {
    return SectionType.B1;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    this.deserialize(props);
    this.carouselViewModel = new CarouselViewModel(this.picture);
  }

  onEnter = () => {
    this.carouselViewModel.onEnter?.();
  };

  onLeave = () => {
    this.carouselViewModel.onLeave?.();
  };

  @action
  deserialize = (params) => {
    const {
      id,
      slogan,
      picture,
      picturebg,
      labeleg,
      label,
      labelct,
      title,
      description,
      name
    } = params ?? {};

    this.id = id;
    this.slogan = slogan;
    this.picture = picture;
    this.picturebg = picturebg;
    this.labeleg = labeleg;
    this.label = label;
    this.labelct = labelct;
    this.title = title;
    this.description = description;
    this.name = name;
  };

  @computed
  get activeIndex() {
    return this.carouselViewModel.activeIndex;
  }

  @computed
  get isLastIndex() {
    return this.activeIndex === this.carouselViewModel.slideRefs.length - 1;
  }
}
