import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Carousel from '../../Carousel';

import PxCode from './P003';

import styles from './styles.module.css';

@observer
class SectionB2 extends React.Component {
  render() {
    const { viewModel } = this.props;
    const { carouselViewModel } = viewModel;
    const carousel = (
      <div className={styles.carouselContainer}>
        <Carousel
          viewModel={carouselViewModel}
          className={styles.ytVideo}
        />
        <div className={styles.bgContainer}>
          <img src={viewModel.picturebg} alt="bg" />
        </div>
      </div>
    );
    const _renderPreBtn = (
      <>
        {
          carouselViewModel.slides.length !== 1
            ? (
              <div
                className={clsx(styles.preBtnContainer, viewModel.activeIndex === 0 ? styles.disabled : null)}
                onClick={() => carouselViewModel.carousel.slidePrev()}
              >
                <div className={clsx(styles.preBtn)} />
              </div>
            )
            : null
        }
      </>
    );
    const _renderNextBtn = (
      <>
        {
          carouselViewModel.slides.length !== 1
            ? (
              <div
                className={clsx(styles.nextBtnContainer, viewModel.isLastIndex && styles.disabled)}
                onClick={() => carouselViewModel.carousel.slideNext()}
              >
                <div className={clsx(styles.nextBtn)} />
              </div>
            )
            : null
        }
      </>
    );
    return (
      <div>
        <PxCode
          labeleg={viewModel.labeleg}
          label={viewModel.label}
          labelct={viewModel.labelct}
          title={viewModel.title}
          description={viewModel.description}
          // shoppingbutton={viewModel.shoppingbutton}
          carousel={carousel}
          _renderPreBtn={_renderPreBtn}
          _renderNextBtn={_renderNextBtn}
        />
      </div>
    );
  }
}

SectionB2.defaultProps = {
  viewModel: {}
};

SectionB2.propTypes = {
  viewModel: PropTypes.object
};


export default SectionB2;
