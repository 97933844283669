import { useState, useMemo } from 'react';
import { cities, districts } from './data';

export default function useTwZipCode() {
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');

  const cityOptions = useMemo(() => {
    const options = cities.map((el) => ({ label: el, value: el }));
    return [{ label: '城市', value: '' }, ...options];
  }, []);

  const districtOptions = useMemo(() => {
    if (city === '') {
      return [{ label: '地區', value: '' }];
    }
    const options = districts[city].map((el) => ({ label: el, value: el }));
    return [{ label: '地區', value: '' }, ...options];
  }, [city]);

  const onCityChange = async (value) => {
    await setCity(value);
    await setDistrict(districts[value]);
  };

  const onDistrictChange = async (value) => {
    await setDistrict(value);
  };

  return {
    cityOptions,
    districtOptions,
    onCityChange,
    onDistrictChange
  };
}
