import React from 'react';
import { observer } from 'mobx-react';

import ChatButton from './ChatButton';

const CommBundle = observer(() => {
  return (
    <div id="bundle">
      <ChatButton />

    </div>
  );
});

export default CommBundle;
