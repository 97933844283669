import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './style.module.css';

const ErrorMessage = (props) => {
  const { className, error, label, ...others } = props;
  return (
    <>
      {error && (
        <div className={clsx(styles.error, className)} {...others}>
          {label}
        </div>
      )}
    </>
  );
};

ErrorMessage.defaultProps = {
  className: '',
  error: null,
  label: ''
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string
};

export default ErrorMessage;
