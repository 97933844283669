import warningImg from 'src/assets/warning_modal.png';
import infoImg from 'src/assets/info_modal.png';
import successImg from 'src/assets/success_modal.png';
import errorImg from 'src/assets/error_modal.png';
import loadingGif from 'src/assets/loading.gif';
import styles from './styles.module.css';

const Theme = {
  warning: 'warning',
  success: 'success',
  info: 'info',
  error: 'error',
  loading: 'loading'
};

const themes = {
  [Theme.warning]: {
    img: warningImg,
    imgClass: styles.warning_img,
    backgroundColor: '#FFBF00'
  },
  [Theme.success]: {
    img: successImg,
    imgClass: styles.success_img,
    backgroundColor: '#4AD395'
  },
  [Theme.info]: {
    img: infoImg,
    imgClass: styles.info_img,
    backgroundColor: '#3E6BE1'
  },
  [Theme.error]: {
    img: errorImg,
    imgClass: styles.error_img,
    backgroundColor: '#800000'
  },
  [Theme.loading]: {
    img: loadingGif,
    imgClass: styles.loading_gif,
    backgroundColor: '#FFFFFF'
  }
};

export { Theme, themes };
