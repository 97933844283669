import { useState, useMemo } from 'react';

import dayjs from 'dayjs';

export default function useBirth() {
  // 滿18歲才可以註冊
  const maxYear = dayjs().year() - 18;
  const [month, setMonth] = useState(dayjs().month() + 1);
  const [year, setYear] = useState(dayjs().year() - 18);

  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = 1930; i <= maxYear; i += 1) {
      options.push({ label: `${i}`, value: i });
    }
    return [{ label: '年', value: '' }, ...options];
  }, []);

  const monthOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 12; i += 1) {
      options.push({ label: `${i}`, value: i });
    }
    return [{ label: '月', value: '' }, ...options];
  }, []);

  const dayOptions = useMemo(() => {
    const days = dayjs(`${year}-${month}-1`).daysInMonth();
    const options = [];
    for (let i = 1; i <= days; i += 1) {
      options.push({ label: `${i}`, value: i });
    }
    return [{ label: '日', value: '' }, ...options];
  }, [month, year]);

  const onMonthChange = async (value) => {
    await setMonth(value);
  };

  const onYearChange = async (value) => {
    await setYear(value);
  };

  return {
    yearOptions,
    monthOptions,
    dayOptions,
    onMonthChange,
    onYearChange
  };
}
