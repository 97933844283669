/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';
import arrowImg from '../../../../../assets/goShoppingArrow.png';

export default function P003(props) {
  return (
    <div className={`p003 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="0px 428fr 0px"
        y="126fr minmax(0px, max-content) 126fr"
        className={css(styles.flex)}
      >
        <div className={css(styles.carousel)}>
          {props.carousel}
          {props._renderPreBtn}
          {props._renderNextBtn}
        </div>
        <div className={css(styles.flex1, styles.flex1_layout)}>
          <div className={css(styles.group1, styles.group1_layout)}>
            <div className={css(styles.labeleg_box, styles.labeleg_box_layout)}>
              <pre className={css(styles.labeleg)}>{props.labeleg}</pre>
              <div
                style={{ '--color': props.label }}
                className={css(styles.label, styles.label_layout)}
              />
            </div>
          </div>

          <h3 className={css(styles.labelct_box, styles.labelct_box_layout)}>
            <pre className={css(styles.labelct)}>{props.labelct}</pre>
          </h3>
          <h1 className={css(styles.title, styles.title_layout)}>
            {props.title}
          </h1>
          <h5 className={css(styles.description, styles.description_layout)}>
            {props.description}
          </h5>
        </div>

        {/* <div className={css(styles.flex_row)}>
          <div
            className={css(styles.cover_group, styles.cover_group_layout)}
            style={{
              borderRadius: props.shoppingbutton.curveFillet,
              backgroundColor: props.shoppingbutton.color
            }}
          >
            <Link
              to="/pickout"
              className={css(
                styles.highlights_wrap,
                styles.highlights_wrap_layout
              )}
            >
              <div className={css(styles.highlights_wrap_spacer)} />
              <h4 className={css(styles.highlights, styles.highlights_layout)}>
                {props.shoppingbutton.text}
              </h4>
              <div className={css(styles.highlights_wrap_spacer1)} />
              <div
                style={{
                  '--src': `url(${arrowImg})`
                }}
                className={css(styles.decorator, styles.decorator_layout)}
              />
            </Link>
          </div>
        </div> */}
      </Px.div>
    </div>
  );
}

P003.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'visible',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  carousel: {
    position: 'relative',
    width: '95%',
    paddingTop: '75%'
  },
  flex1: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '38px 35px 0px 20px',
    '@media (max-width: 575.98px)': {
      margin: '20px 35px 0px 20px'
    }
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: 0
  },
  label: {
    backgroundColor: 'var(--color)'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: -1,
    left: 0,
    width: '100%',
    zIndex: 0
  },
  labeleg: {
    position: 'relative',
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 12px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '10px',
      textAlign: 'left'
    },
    zIndex: 1
  },
  labeleg_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labeleg_box_layout: {
    position: 'relative',
    margin: 0
  },
  labelct: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 20px/1 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '17px',
      textAlign: 'left'
    }
  },
  labelct_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labelct_box_layout: {
    position: 'relative',
    margin: '1px 0px 0px'
  },
  title: {
    font: '900 32px/1.375 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '26px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 0px 0px'
  },
  description: {
    font: '16px/1.875 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    margin: 0,
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '12px',
      textAlign: 'left'
    }
  },
  description_layout: {
    position: 'relative',
    margin: '11px 0px 0px'
  },
  flex_row: {
    display: 'flex'
  },
  cover_group: {
    display: 'flex',
    backgroundColor: 'rgb(41,41,41)'
  },
  cover_group_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    margin: '55px auto 0px',
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '30px auto 0px'
    }
  },
  highlights_wrap: {
    display: 'flex',
    alignItems: 'center'
  },
  highlights_wrap_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: '12px 26.5px'
  },
  highlights_wrap_spacer: {
    display: 'flex',
    flex: '0 1 9px'
  },
  highlights: {
    display: 'flex',
    justifyContent: 'center',
    font: '18px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    letterSpacing: '5.625px'
  },
  highlights_layout: {
    position: 'relative',
    flex: '0 1 185px',
    height: 'min-content',
    width: 185,
    minWidth: 185,
    margin: 0
  },
  highlights_wrap_spacer1: {
    display: 'flex',
    flex: '0 1 0.5px'
  },
  decorator: {
    background: 'var(--src) center center / contain no-repeat'
  },
  decorator_layout: {
    position: 'relative',
    flex: '0 1 52.5px',
    height: 8.68,
    width: 52.5,
    minWidth: 52.5,
    margin: '9px 0px 8.32px'
  }
});


P003.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  labeleg: PropTypes.string,
  labelct: PropTypes.string,
  description: PropTypes.string,
  // shoppingbutton: PropTypes.object,
  carousel: PropTypes.element,
  _renderNextBtn: PropTypes.element,
  _renderPreBtn: PropTypes.element
};

P003.defaultProps = {
  title: null,
  label: null,
  labeleg: null,
  labelct: null,
  description: null,
  // shoppingbutton: null,
  carousel: null,
  _renderNextBtn: null,
  _renderPreBtn: null
};
