import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './style.module.css';

function PhoneInput(props) {
  const { name, control, defaultValue, rules, className, style, inputClassName, inputStyle, onInputChange, ...others } = props;
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules
  });

  const { onChange } = field;
  const customizeFunc = (e) => {
    onChange(e);

    if (onInputChange) {
      onInputChange(e);
    }
  };

  return (
    <div className={clsx(styles.layout, className)} style={style}>
      <div className={styles.prefix}>+886</div>
      <input className={clsx(styles.input, inputClassName)} style={inputStyle} {...field} {...others} onChange={customizeFunc} />
    </div>
  );
}

PhoneInput.defaultProps = {
  name: '',
  control: null,
  defaultValue: '',
  rules: null,
  className: '',
  inputClassName: '',
  style: {},
  inputStyle: {},
  onInputChange: undefined
};

PhoneInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  onInputChange: PropTypes.func
};

export default PhoneInput;
