import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.css';

export default function Footer(props) {
  return (
    <footer className={clsx(styles.footer, props.className)}>
      {props.children}
    </footer>
  );
}

Footer.defaultProps = {
  children: null,
  className: null
};

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
