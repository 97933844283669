/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Px, commonStyles } from '../../posize';

function renderIndexdesign1(props) {
  return (
    <div
      className={css(
        indexdesign1Styles.indexdesign1,
        indexdesign1Styles.indexdesign1_layout
      )}
    >
      <LazyLoadImage
        alt=""
        height="100%"
        width="100%"
        src={props.indexdesign1}
        wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
      />

      <Px.div
        x="950fr minmax(0px, max-content) 30px"
        y="865fr minmax(0px, max-content) 140fr"
        className={css(indexdesign1Styles.cover_group)}
        mdX="950fr minmax(0px, max-content) 30px"
        mdY="433fr minmax(0px, max-content) 70fr"
        smX="950fr minmax(0px, max-content) 30px"
        smY="217fr minmax(0px, max-content) 35fr"
        xsX="950fr minmax(0px, max-content) 30px"
        xsY="109fr minmax(0px, max-content) 25fr"
      >
        {/* <div
          className={css(
            indexdesign1Styles.small_text_body,
            indexdesign1Styles.small_text_body_layout
          )}
        >
          設計風格示意圖
        </div> */}
      </Px.div>
    </div>
  );
}

export default function Promo_004(props) {
  return (
    <div className={`promo-004 ${css(styles.group, styles.group_layout)}`}>
      <div className={css(styles.flex, styles.flex_layout2)}>
        <div className={css(styles.flex1, styles.flex1_layout)}>
          <div className={css(styles.flex, styles.flex_layout)}>

            <div
              className={`${css(styles.indexdesign2, styles.indexdesign2_layout)} swiper-lazy`}
            >
              <LazyLoadImage
                alt=""
                height="100%"
                width="100%"
                src={props.indexdesign2}
                wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
              />
            </div>


            <div
              className={`${css(styles.indexdesign3, styles.indexdesign3_layout)} swiper-lazy`}
            >
              <LazyLoadImage
                alt=""
                height="100%"
                width="100%"
                src={props.indexdesign3}
                wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
              />

            </div>
          </div>

          <div className={css(styles.flex, styles.flex_layout)}>
            <div
              className={`${css(styles.indexdesign4, styles.indexdesign4_layout)} swiper-lazy`}
            >
              <LazyLoadImage
                alt=""
                height="100%"
                width="100%"
                src={props.indexdesign4}
                wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
              />

            </div>
            <div
              className={`${css(styles.indexdesign5, styles.indexdesign5_layout)} swiper-lazy`}
            >
              <LazyLoadImage
                alt=""
                height="100%"
                width="100%"
                src={props.indexdesign5}
                wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
              />

            </div>
          </div>
        </div>

        <div className={css(styles.flex_col)}>{renderIndexdesign1(props)}</div>
      </div>
    </div>
  );
}

Promo_004.inStorybook = true;

const indexdesign1Styles = StyleSheet.create({
  indexdesign1_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  indexdesign1: {
    display: 'flex',
    background: 'center center / cover no-repeat'
  },
  cover_group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '10px 10px 10px 10px',
    width: '100%',
    height: '100%'
  },
  small_text_body: {
    display: 'flex',
    justifyContent: 'center',
    font: '11px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px'
  },
  small_text_body_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: '3px 10px',
    '@media (max-width: 991.98px)': {
      width: 'fit-content',
      margin: '3px 3.5px'
    }
  }
});

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex'
  },
  flex_layout2: {
    position: 'relative',
    overflow: 'visible',
    minHeight: 440,
    flexGrow: 1,
    minWidth: 768,
    margin: '48px 0px 0px',
    '@media (max-width: 1919.98px)': {
      minHeight: 440,
      flexGrow: 1,
      minWidth: 768,
      margin: '48px 0px 0px'
    },
    '@media (max-width: 1399.98px)': {
      minHeight: 440,
      flexGrow: 1,
      minWidth: 768,
      margin: '48px 0px 0px'
    }
  },
  flex1: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    flex: '1 0 auto',
    margin: 0
  },
  flex_layout: {
    position: 'relative',
    overflow: 'visible',
    flex: '1 1 auto',
    margin: 0
  },
  indexdesign2: {
    background: 'center center / cover no-repeat'
  },
  indexdesign2_layout: {
    position: 'relative',
    flex: '1 0 auto',
    margin: 0
  },
  indexdesign3: {
    background: 'center center / cover no-repeat'
  },
  indexdesign3_layout: {
    position: 'relative',
    flex: '1 0 auto',
    margin: 0
  },
  indexdesign4: {
    background: 'center center / cover no-repeat'
  },
  indexdesign4_layout: {
    position: 'relative',
    flex: '1 0 auto',
    margin: 0
  },
  indexdesign5: {
    background: 'center center / cover no-repeat'
  },
  indexdesign5_layout: {
    position: 'relative',
    flex: '1 0 auto',
    margin: 0
  },
  flex_col: {
    display: 'flex',
    flex: '1 1 auto'
  }
});

Promo_004.propTypes = {
  indexdesign2: PropTypes.string,
  indexdesign3: PropTypes.string,
  indexdesign4: PropTypes.string,
  indexdesign5: PropTypes.string
};

Promo_004.defaultProps = {
  indexdesign2: null,
  indexdesign3: null,
  indexdesign4: null,
  indexdesign5: null
};
