/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './style.module.css';

function Button(props) {
  const { children, className, type, onClick, disabled, value, style } = props;
  return (
    <button
      className={clsx(styles.button, className)}
      onClick={onClick}
      type={type}
      disabled={disabled}
      value={value}
      style={style}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  children: undefined,
  className: '',
  type: 'button',
  disabled: false,
  onClick: () => {},
  value: '',
  style: null
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.object
};

export default Button;
