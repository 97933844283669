import { observable, makeObservable, action, computed } from 'mobx';

export default class PopupsViewModel {
  @observable showStatus = false;
  @observable popupList = [];
  @observable carousel = null;
  @observable activeIndex = 0;

  constructor(props) {
    makeObservable(this);

    this.createLists(props);
  }

  @action
  createLists = (list) => {
    this.popupList = list || [];
  }

  @action
  showPopup = () => {
    this.showStatus = true;
  }

  @action
  closePopup = () => {
    this.showStatus = false;
    this.carousel.slideTo(0);
  }

  @action
  onSwiper = (swiper) => {
    this.carousel = swiper;
  };

  @action
  onActiveIndexChange = () => {
    this.activeIndex = this.carousel.activeIndex;
  }

  @computed
  get isLastSlide() {
    return this.activeIndex === this.popupList.length - 1;
  }
}
