import Profile from './Profile';

export default class Signup {
  constructor(params) {
    const { profile, token } = params ?? {};

    this.profile = profile ? Profile.fromRes(profile) : null;
    this.token = token ?? null;
  }

  static fromRes(data) {
    return new Signup(data);
  }
}
