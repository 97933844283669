import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';

export default function Icon(props) {
  return (
    <div className={`icon ${css(styles.flex, styles.flex_layout)}`}>
      <div
        style={{ '--src': `url(${props.info_icon})` }}
        className={css(styles.info_icon, styles.info_icon_layout)}
      />
      <div className={css(styles.flex_spacer)} />
      <div className={css(styles.flex_col)}>
        <Px
          x="0px 122fr 0px"
          y="23fr minmax(0px, max-content) 23fr"
          className={css(styles.info_box)}
        >
          <pre className={css(styles.info)}>{props.info}</pre>
        </Px>
      </div>
    </div>
  );
}

Icon.inStorybook = true;

const styles = StyleSheet.create({
  flex: {
    display: 'flex'
  },
  flex_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  info_icon: {
    background: 'var(--src) center center / contain no-repeat'
  },
  info_icon_layout: {
    position: 'relative',
    flex: '0 1 70px',
    height: 70,
    width: 70,
    minWidth: 70,
    margin: 0
  },
  flex_spacer: {
    display: 'flex',
    flex: '0 1 15px'
  },
  flex_col: {
    display: 'flex',
    flex: '0 1 122px'
  },
  info: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '400 12px/1.2 "Noto Sans TC", Helvetica, Arial, serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0.875px'
  },
  info_box: {
    width: '100%',
    height: '100%'
  }
});

Icon.propTypes = {
  info_icon: PropTypes.string,
  info: PropTypes.string
};

Icon.defaultProps = {
  info_icon: null,
  info: null
};
