import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Image,
  View,
  StyleSheet
} from '@react-pdf/renderer';

import PlanJson from 'src/json/plan.json';
import SectionsJson from 'src/json/sections.json';

const getBottomImages = (buildingName, floorName) => {
  const building = PlanJson.buildings?.find((b) => b.name === buildingName);
  const floor = building?.floors?.find((f) => f.name === floorName);

  return {
    building: building?.pic?.filter((p) => `${p.type}` === 'image')?.[0]?.value ?? null,
    floor: floor?.pic?.filter((p) => `${p.type}` === 'image')?.[0]?.value ?? null
  };
};

const About = (props) => {
  // first section a
  const sectionA = SectionsJson.sections?.find((s) => `${s.type}`.toLowerCase() === 'a');

  // last section g
  const gs = SectionsJson.sections?.filter((s) => `${s.type}`.toLowerCase() === 'g');
  const sectionG = gs[gs.length - 1];

  const info1 = sectionG?.infoSubTit1;
  const info2 = sectionG?.infoSubTit2;

  const newLine = info1 && info2 ? '\n' : '';

  const metas = sectionA ? [
    {
      label: sectionA.description1,
      intro: sectionA.intro1
    },
    {
      label: sectionA.description2,
      intro: sectionA.intro2
    },
    {
      label: sectionA.description3,
      intro: sectionA.intro3
    },
    {
      label: sectionA.description4,
      intro: sectionA.intro4
    }
  ] : null;

  const bottomImages = getBottomImages(
    props.order?.door?.floor?.building?.name,
    props.order?.door?.floor?.name
  );

  return (
    <View style={styles.container}>

      <Text style={styles.tag}>ABOUT</Text>

      {
        metas.length ? (
          <View style={styles.metas}>
            {
              metas.map((m, idx) => {
                const label = m.label.slice(0, m.label.length - 1);
                const unit = m.label.slice(-1);

                return (
                  <View
                    key={`${label}`}
                    style={styles.item}
                  >
                    <View style={styles.itemRow}>
                      <Text style={styles.label}>{ label }</Text>
                      <Text style={styles.unit}>{ unit }</Text>
                    </View>
                    <View style={styles.itemFooter}>
                      <Text style={styles.itemIntro}>{ m.intro }</Text>
                    </View>
                  </View>
                );
              })
            }
          </View>
        ) : null
      }

      {
        sectionG ? (
          <View style={styles.info}>
            <Text style={styles.infoTxt}>
              { `${info1}${newLine}${info2}` }
            </Text>
          </View>
        ) : null
      }

      <View style={styles.images}>
        <Image
          style={styles.img}
          source={bottomImages.building}
        />

        <Image
          style={styles.img}
          source={bottomImages.floor}
        />
      </View>
    </View>
  );
};

About.propTypes = {
  order: PropTypes.object
};

About.defaultProps = {
  order: null
};

const styles = StyleSheet.create({
  container: {
    margin: '10px 40px 30px 40px',
    display: 'flex',
    flexDirection: 'column'
    // border: '1px solid red'
  },
  tag: {
    fontFamily: 'custom',
    fontWeight: 700,
    fontSize: 14,
    color: '#BDBDBD'
  },
  metas: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10
  },
  item: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  label: {
    fontFamily: 'custom',
    fontSize: 28
  },
  unit: {
    fontFamily: 'custom',
    fontSize: 16
  },
  itemFooter: {
    marginTop: 5
  },
  itemIntro: {
    fontFamily: 'custom',
    fontSize: 12,
    textAlign: 'center'
  },
  info: {
    marginTop: 40
  },
  infoTxt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#282828',
    lineHeight: 2
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    margin: '50px 0 57px 0',
    justifyContent: 'space-between'
  },
  img: {
    border: '1px solid red',
    // height: 238,
    width: 242
  }
});

export default About;
