import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  StyleSheet
} from '@react-pdf/renderer';

import SectionsJson from 'src/json/sections.json';

import Map from './Map';

const Location = (props) => {
  // first section a
  const sectionA = SectionsJson.sections?.find((s) => `${s.type}`.toLowerCase() === 'a');

  // last section g
  const gs = SectionsJson.sections?.filter((s) => `${s.type}`.toLowerCase() === 'g');
  const sectionG = gs[gs.length - 1];

  return (
    <View style={styles.container}>

      <View style={styles.row}>
        <View style={styles.left}>
          <Text style={styles.tag}>LOCATION</Text>
        </View>

        <View style={styles.right}>
          <Text style={styles.slogan}>{ sectionA?.subtitle1 ?? '' }</Text>
          <Text style={styles.slogan}>{ sectionA?.subtitle2 ?? '' }</Text>
        </View>
      </View>

      <Map url={sectionG.map} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: '50px 40px 25px 40px',
    display: 'flex',
    flexDirection: 'column'
    // border: '1px solid red'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  left: {

  },
  right: {
    flex: 1,
    margin: '5px 40px 30px 30px',
    overflow: 'hidden'
    // border: '1px solid red'
  },
  tag: {
    fontFamily: 'custom',
    fontWeight: 700,
    fontSize: 14,
    color: '#BDBDBD'
  },
  slogan: {
    fontFamily: 'custom',
    fontSize: 16,
    lineHeight: 2,
    fontWeight: 300,
    color: '#282828'
  }
});

export default Location;
