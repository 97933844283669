/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { Px, commonStyles } from '../../posize';

export default function C06(props) {
  return (
    <div
      className={`c06 ${css(
        styles.paragraph_body_wrap,
        styles.paragraph_body_wrap_layout
      )}`}
    >
      <Px.div
        style={{ '--src': `url(${props.image})` }}
        x="0px 34.05px 84.95fr"
        y="2.6fr 47.39px 1fr"
        absolute
        className={css(styles.image)}
      />
      <div
        className={css(
          styles.small_paragraph_body_box,
          styles.small_paragraph_body_box_layout
        )}
      >
        <pre className={css(styles.small_paragraph_body)}>
          {props.small_paragraph_body}
        </pre>
      </div>
    </div>
  );
}

C06.inStorybook = true;

const styles = StyleSheet.create({
  paragraph_body_wrap: {
    display: 'flex'
  },
  paragraph_body_wrap_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  image: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  small_paragraph_body: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '13px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '8px',
      textAlign: 'left'
    }
  },
  small_paragraph_body_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  small_paragraph_body_box_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: '9px 0px 11px 51px',
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '9px 0px 11px 36px'
    }
  }
});

C06.propTypes = {
  image: PropTypes.string,
  small_paragraph_body: PropTypes.string
};

C06.defaultProps = {
  image: null,
  small_paragraph_body: null
};
