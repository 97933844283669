import React, { useEffect, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import is from 'is_js';
import isEmail from 'validator/lib/isEmail';
import csModule from 'src/services/csModule';

import useTwZipCode from 'src/hooks/useTwZipCode';
import useBirth from 'src/hooks/useBirth';
import useJob from 'src/hooks/useJob';
import UserService from 'src/services/user';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import Input from 'src/components/Common/Input';
import Radio from 'src/components/Common/Radio';
import Select from 'src/components/Common/Select';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import { Theme } from 'src/components/Modal/themes';

import styles from './styles.module.css';

function Form({ onSubmit, values, disabled }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues
  } = useForm();

  const { yearOptions, monthOptions, dayOptions, onMonthChange, onYearChange } = useBirth();
  const { cityOptions, districtOptions, onCityChange } = useTwZipCode();
  const history = useHistory();
  const { actions } = useAppStateContext();
  const formRef = useRef();

  const yearRegistration = register('year');
  const monthRegistration = register('month');
  const dayRegistration = register('day');
  const cityRegistration = register('city');
  const districtRegistration = register('region');

  const _convertBirth = useCallback((year, month, day) => {
    if (is.empty(year) || is.empty(month) || is.empty(day)) return null;
    const m = month < 10 ? `0${month}` : month;
    const d = day < 10 ? `0${day}` : day;
    const birth = `${year}${m}${d}`;
    return Number(birth);
  }, []);

  const _onSubmit = useCallback(async (data) => {
    const req = {
      ...data,
      birthday: _convertBirth(data.year, data.month, data.day)
    };

    const isInvalid = Object.values(data).includes('');
    if (isInvalid) {
      return;
    }

    await onSubmit(req);
  }, [_convertBirth, onSubmit]);

  useEffect(() => {
    if (is.empty(values) || !values) return;
    setValue('email', values.email);
    setValue('lastName', values.lastName);
    setValue('firstName', values.firstName);
    setValue('postalCode', values.postalCode);
    setValue('address', values.address);
  }, [values, setValue]);

  useEffect(() => {
    if (is.empty(values) || !values) return;
    const initAddress = async () => {
      setValue('city', values.city);
      // region 列表要先選擇 city 才會有
      await onCityChange(values.city);
      await setValue('region', values.region);
    };

    initAddress();
  }, [values, setValue]);

  useEffect(() => {
    if (is.empty(values) || !values) return;
    const initBirth = () => {
      if (values.birthday !== '') {
        const year = values.birthday.toString().slice(0, 4);
        const month = values.birthday.toString().slice(4, 6);
        const day = values.birthday.toString().slice(6, 8);
        setValue('year', year);
        // 處理零開頭的個位數， ex: 06 轉成 6
        setValue('month', (month ? Number(month).toString() : ''));
        setValue('day', (day ? Number(day).toString() : ''));
      }
    };

    initBirth();
  }, [values, setValue]);


  const _logout = async () => {
    try {
      actions.showModal({
        theme: Theme.warning,
        title: 'INFO',
        content: '確定要登出嗎?',
        cancelText: '先不要',
        okText: '確定',
        onOk: async () => {
          await UserService.logout();
          actions.updateProfile(null);
          history.push('/login');
          // NOTE: end of cs module.
          csModule.end();
        },
        onCancel: () => {}
      });
    } catch (err) {
      console.log('logout error: ', err.response);
    }
  };

  const formValues = getValues();

  return (
    <div className={styles.layout}>
      <fieldset disabled={disabled}>
        <form ref={formRef} id="userCardForm" onSubmit={handleSubmit(_onSubmit)}>

          <div className={styles.row}>
            <div className={styles.form_title}>帳號</div>
          </div>

          <div className={styles.row}>

            <div className={styles.inputContainer}>

              <span className={clsx(styles.phone, styles.phone_bold)}>{values.telephone.substring(0, 4)}</span>

              <span className={clsx(styles.phone)}>{values.telephone.substring(4)}</span>

            </div>

            <div className={styles.logout} onClick={_logout}>
              登出
            </div>
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <div className={styles.form_title}>
              姓名
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.lastName}>
              <div className={errors.lastName ? styles.inputErrorContainer : styles.inputContainer}>
                <Input
                  control={control}
                  name="lastName"
                  rules={{ required: true }}
                  inputClassName={styles.form_input}
                  onBlur={handleSubmit(_onSubmit)}
                  error={errors.lastName}
                />
              </div>
            </div>

            <div className={styles.firstName}>
              <div className={errors.firstName ? styles.inputErrorContainer : styles.inputContainer}>
                <Input
                  control={control}
                  name="firstName"
                  rules={{ required: true }}
                  inputClassName={styles.form_input}
                  onBlur={handleSubmit(_onSubmit)}
                  error={errors.firstName}
                />
              </div>
            </div>

          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <div className={styles.form_title}>
              電子郵件地址
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.email}>

              <div className={errors.email ? styles.inputErrorContainer : styles.inputContainer}>
                <Input
                  control={control}
                  name="email"
                  placeholder="請輸入電子郵件地址"
                  rules={{
                    validate: (value) => isEmail(value)
                  }}
                  inputClassName={styles.form_input}
                  onBlur={handleSubmit(_onSubmit)}
                  error={errors.email}
                />
              </div>
            </div>
          </div>

          <div className={styles.row} style={{ marginTop: 20 }}>
            <div className={styles.form_title}>生日</div>
          </div>
          <div className={styles.row}>
            <div className={styles.form_title}>會員應為法人或其所在國家地域法定成年人，始得註冊為本網站會員。</div>
          </div>


          <div className={styles.row}>
            <div className={styles.year}>
              <div className={!formValues?.year ? styles.inputErrorContainer : styles.inputContainer}>
                <Select
                  className={styles.form_select}
                  options={yearOptions}
                  {...yearRegistration}
                  onChange={(e) => {
                    onYearChange(e.target.value);
                    yearRegistration.onChange(e);
                    handleSubmit(_onSubmit)();
                  }}
                />
              </div>
            </div>

            <div className={styles.month}>
              <div className={!formValues?.month ? styles.inputErrorContainer : styles.inputContainer}>
                <Select
                  className={styles.form_select}
                  options={monthOptions}
                  {...monthRegistration}
                  onChange={(e) => {
                    onMonthChange(e.target.value);
                    monthRegistration.onChange(e);
                    setValue('day', '');
                  }}
                />
              </div>
            </div>

            <div className={styles.day}>
              <div className={!formValues?.day ? styles.inputErrorContainer : styles.inputContainer}>
                <Select
                  className={styles.form_select}
                  options={dayOptions}
                  {...dayRegistration}
                  onChange={(e) => {
                    dayRegistration.onChange(e);
                    handleSubmit(_onSubmit)();
                  }}
                />
              </div>
            </div>
          </div>

          {
            values.group === 'consumer' && (
            <>
              <div className={styles.row} style={{ marginTop: 20 }}>
                <div className={styles.form_title}>聯絡暨合約地址</div>
              </div>

              <div className={styles.row}>
                <div className={styles.city}>
                  <div className={!values?.city ? styles.inputErrorContainer : styles.inputContainer}>
                    <Select
                      className={styles.form_select}
                      options={cityOptions}
                      {...cityRegistration}
                      onChange={(e) => {
                        onCityChange(e.target.value);
                        cityRegistration.onChange(e);
                        setValue('region', '');
                      }}
                    />
                  </div>
                </div>

                <div className={styles.region}>
                  <div className={!values?.region ? styles.inputErrorContainer : styles.inputContainer}>
                    <Select
                      className={styles.form_select}
                      options={districtOptions}
                      {...districtRegistration}
                      onChange={(e) => {
                        districtRegistration.onChange(e);
                        handleSubmit(_onSubmit)();
                      }}
                    />
                  </div>
                </div>

                <div className={styles.postalCode}>
                  <div className={!values?.postalCode ? styles.inputErrorContainer : styles.inputContainer}>
                    <Input
                      control={control}
                      name="postalCode"
                      placeholder="郵遞區號"
                      inputClassName={styles.form_input}
                      onBlur={handleSubmit(_onSubmit)}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.row} style={{ marginBottom: 0 }}>
                <div className={styles.address}>
                  <div className={!values?.address ? styles.inputErrorContainer : styles.inputContainer}>
                    <Input
                      control={control}
                      rules={{ required: true }}
                      name="address"
                      placeholder="街道, 巷弄, 門號, 樓層"
                      inputClassName={styles.form_input}
                      onBlur={handleSubmit(_onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </>
            )
          }
        </form>
      </fieldset>
    </div>
  );

}

Form.defaultProps = {
  onSubmit: () => {},
  values: {},
  disabled: false
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  disabled: PropTypes.bool
};

export default Form;
