
export default class Label {
  constructor(params) {
    const { key, name } = params ?? {};
    this.key = key;
    this.name = name;
  }

  static fromRes(data) {
    return new Label(data);
  }
}
