import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import Model from './Model';

@observer
class PopupText extends React.Component {
  constructor(props) {
    super(props);
    this.model = new Model(props.viewModel);
  }
  render() {
    return (
      <div className={styles.popupContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {this.model.title}
          </div>
          <div className={styles.subtitle}>
            {this.model.subtitle}
          </div>
        </div>
        <div className={styles.description}>
          {this.model.description}
        </div>
      </div>
    );
  }
}

PopupText.propTypes = {
  viewModel: PropTypes.object
};

PopupText.defaultProps = {
  viewModel: null
};

export default PopupText;
