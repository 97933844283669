import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import YouTube from 'src/components/YouTube';
import CustomizeModal from 'src/components/CustomizeModal';
import CustomizseYouTube from 'src/components/CustomizeYoutube';

import styles from './styles.module.css';
import Pxcode from './Promo_001';

@observer
class SectionA extends Component {
  render() {
    const { viewModel } = this.props;
    return (
      <>
        <div className={styles.sectionContainer}>
          <Pxcode
            background={viewModel.background}
            iframe={viewModel.iframe}
            subtitle1={viewModel.subtitle1}
            subtitle2={viewModel.subtitle2}
            // shoppingbutton={viewModel.shoppingbutton}
            description1={viewModel.description1}
            description2={viewModel.description2}
            description3={viewModel.description3}
            description4={viewModel.description4}
            description1Unit={viewModel.description1Unit}
            description2Unit={viewModel.description2Unit}
            description3Unit={viewModel.description3Unit}
            description4Unit={viewModel.description4Unit}
            intro1={viewModel.intro1}
            intro2={viewModel.intro2}
            intro3={viewModel.intro3}
            intro4={viewModel.intro4}
            casetitle={viewModel.casetitle}
            openVideo={viewModel.openVideo}
            videoIcon={viewModel.videoIcon}
            videoName={viewModel.videoName}
          />
          <CustomizeModal
            shouldIgnorePuppeteer
            className={clsx(styles.ytPopup, viewModel.popup.isShow ? styles.ytShow : styles.ytHide)}
            bgColor="#fff"
          >
            <div className={styles.contentContainer}>
              <div className={styles.ytContainer}>
                <CustomizseYouTube ref={viewModel.videoRef} value={viewModel.iframe} className={styles.ytVideo} />
              </div>
              <div
                className={styles.closeBtn}
                onClick={viewModel.closeVideo}
              />
            </div>
          </CustomizeModal>
        </div>
      </>
    );
  }
}

SectionA.defaultProps = {
  viewModel: {}
};

SectionA.propTypes = {
  viewModel: PropTypes.object
};

export default SectionA;
