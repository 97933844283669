/* eslint-disable react/jsx-pascal-case */
/**
 * This source code is exported from pxCode, you can get more document from https://www.pxcode.io
 */
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { Px, commonStyles } from '../../posize';

export default function P05(props) {
  return (
    <div className={`p05 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="28px 400fr 0px"
        y="0fr minmax(0px, 395fr) 511fr"
        absolute
        className={css(styles.group1)}
      >
        <Px.div
          data-background={props.indexdesignerbg}
          x="128px 205fr 67px"
          y="40fr minmax(0px, 322fr) 10fr"
          absolute
          className={`${css(styles.background)} swiper-lazy`}
        />
        <Px.div
          data-background={props.indexdesigner1}
          x="1fr 400fr 1fr"
          y="0fr minmax(0px, 500fr) 30fr"
          absolute
          className={`${css(styles.decorator)} swiper-lazy`}
        />
      </Px.div>

      <Px.div
        x="0px 400fr 28px"
        y="680fr minmax(100px,356fr) 0px"
        absolute
        className={css(styles.group2)}
      >
        <Px.div
          data-background={props.indexdesignerbg}
          x="37fr 205fr 158fr"
          y="20fr minmax(100px,322fr) 2fr"
          absolute
          className={`${css(styles.background)} swiper-lazy`}
        />
        <Px.div
          data-background={props.indexdesigner2}
          x="0px 400fr 0px"
          y="0fr minmax(0px, 300fr) 0fr"
          className={`${css(styles.decorator)} swiper-lazy`}
          xsX="0px 400fr 0px"
          xsY="0fr minmax(0px, 300fr) 0fr"
        />
      </Px.div>

      <Px.div
        x="16px 396fr 16px"
        y="1fr minmax(0px, max-content) 1fr"
        className={css(styles.flex)}
      >
        <div className={css(styles.flex_spacer)} />
        <div className={css(styles.flex_row)}>
          <div className={css(styles.group3, styles.group3_layout)}>
            <div className={css(styles.labeleg, styles.labeleg_layout)}>
              {props.labeleg}
            </div>
            <div
              style={{ '--color': props.label }}
              className={css(styles.label, styles.label_layout)}
            />
          </div>
        </div>
        <h3 className={css(styles.labelct, styles.labelct_layout)}>
          {props.labelct}
        </h3>
        <h1 className={css(styles.title, styles.title_layout)}>
          {props.title}
        </h1>
        <h5 className={css(styles.description, styles.description_layout)}>
          {props.description}
        </h5>
        <div className={css(styles.flex_spacer)} />
      </Px.div>
    </div>
  );
}

P05.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  group1: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  decorator: {
    background: 'center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  background: {
    background: 'center center / cover no-repeat',
    width: '100%',
    height: '100%'
  },
  group2: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  flex_spacer: {
    display: 'flex',
    flex: '0 5 322px'
  },
  flex_row: {
    display: 'flex'
  },
  group3: {
    display: 'flex'
  },
  group3_layout: {
    position: 'relative',
    overflow: 'visible',
    height: 14,
    width: 'fit-content',
    margin: '97px 0px 0px 4px',
    '@media (max-width: 575.98px)': {
      height: 14,
      width: 'fit-content',
      margin: '30px 0px 0px 4px'
    }
  },
  label: {
    backgroundColor: 'var(--color)'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: 1,
    left: 0,
    width: '100%',
    zIndex: 0
  },
  labeleg: {
    font: '900 12px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '10px',
      textAlign: 'left'
    }
  },
  labeleg_layout: {
    position: 'relative',
    height: 14,
    width: 'fit-content',
    margin: 0,
    zIndex: 1
  },
  labelct: {
    font: '900 20px/1 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '17px',
      textAlign: 'left'
    }
  },
  labelct_layout: {
    position: 'relative',
    margin: '1px 4px 0px'
  },
  title: {
    font: '900 32px/1.375 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '26px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 4px 0px'
  },
  description: {
    font: '16px/1.875 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    margin: 0,
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '12px',
      textAlign: 'left'
    }
  },
  description_layout: {
    position: 'relative',
    margin: '11px 0px 0px 4px'
  }
});

P05.propTypes = {
  indexdesigner1: PropTypes.string,
  indexdesigner2: PropTypes.string,
  indexdesignerbg: PropTypes.string,
  label: PropTypes.string,
  labeleg: PropTypes.string,
  labelct: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};

P05.defaultProps = {
  indexdesigner1: null,
  indexdesigner2: null,
  indexdesignerbg: null,
  label: null,
  labeleg: null,
  labelct: null,
  title: null,
  description: null
};
