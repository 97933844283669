import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { actions } from './actions';
import { reducer } from './reducer';

const initialState = {
  profile: null,
  plan: {},
  modal: null
};

const AppStateContext = React.createContext();
AppStateContext.displayName = 'AppState Context';

const AppStateProvider = (props) => {
  const [appState, dispatch] = useReducer(reducer, initialState);

  return (
    <AppStateContext.Provider
      value={{
        state: appState,
        actions: actions(dispatch)
      }}
    >
      { props.children }
    </AppStateContext.Provider>
  );
};

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AppStateProvider, AppStateContext };
