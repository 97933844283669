import { observable, makeObservable, action } from 'mobx';
import UserService from 'src/services/user';
import isMobile from 'ismobilejs';
import CustomizeModalViewModel from '.';


export default class QrCodeModalViewModel extends CustomizeModalViewModel {
  @observable phoneNum = '0900000000';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  getSalesPhoneNumber = async (targetId) => {
    try {
      const profile = await UserService.getUserProfile(targetId);
      this.phoneNum = profile.telephone;
      if (isMobile(window.navigator).phone) {
        this.makeCall();
      } else {
        this.showModal();
      }
    } catch (error) {
      console.error(error);
    }

  }

  @action
  makeCall = () => {
    window.open(`tel:${this.phoneNum}`);
  }
}
