/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { observer } from 'mobx-react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';


const Promo_005 = observer((props) => {
  return (
    <div className={`promo-005 ${css(styles.group, styles.group_layout)}`}>
      {/* BG */}
      <Px.div
        data-background={props.indexdesignerbg}
        x="656fr minmax(500px,515fr) 749fr"
        y="168fr minmax(0px, 810fr) 102fr"
        absolute
        className={`${css(styles.indexdesignerbg)} swiper-lazy`}
        xlX="656fr minmax(400px,515fr) 749fr"
        xlY="168fr minmax(0px, 810fr) 102fr"
        mdX="656fr minmax(350px,515fr) 749fr"
        mdY="168fr minmax(0px, 810fr) 102fr"
        onClick={props.logRatio}
      />
      {
        props.ratio ? (
          <Px.img
            data-src={`${props.indexdesigner1}`}
            x="39px 895fr 986fr"
            y="9fr minmax(0px, max-content) 135fr"
            absolute
            className={`${css(styles.indexdesigner1)} swiper-lazy`}
            lgX="39px minmax(400px,895fr) 986fr"
            lgY="9fr minmax(0px, max-content) 135fr"
            mdX="39px minmax(400px,895fr) 986fr"
            mdY="9fr minmax(0px, max-content) 135fr"
            mdDisplay="none"
          />) : (
          <Px.img
            data-src={`${props.indexdesigner1}`}
            x="1fr 8fr 4fr"
            y="9fr minmax(0px, max-content) 135fr"
            absolute
            className={`${css(styles.indexdesigner1)} swiper-lazy`}
            lgX="1fr 8fr 4fr"
            lgY="9fr minmax(0px, max-content) 135fr"
            mdX="1fr 8fr 4fr"
            mdY="9fr minmax(0px, max-content) 135fr"
            mdDisplay="none"
          />)
      }
      <Px.img
        data-src={`${props.indexdesigner1}`}
        x="512.5px 895fr 512.5fr"
        y="9fr minmax(0px, max-content) 135fr"
        absolute
        className={`${css(styles.indexdesigner1)} swiper-lazy`}
        maxX="512.5px 895fr 512.5fr"
        maxY="9fr minmax(0px, max-content) 135fr"
        maxDisplay="none"
        lgX="512.5px minmax(400px,895fr) 512.5fr"
        lgY="9fr minmax(0px, max-content) 135fr"
        lgDisplay="none"
        mdX="50px minmax(400px,895fr) 512.5fr"
        mdY="9fr minmax(0px, max-content) 135fr"
        mdDisplay="show"
      />
      {props.ratio ? (
        <Px.img
          data-src={`${props.indexdesigner2}`}
          x="986fr 775fr 159px"
          y="306fr minmax(0px, max-content) 1fr"
          absolute
          className={`${css(styles.indexdesigner2)} swiper-lazy`}
          lgX="986fr minmax(400px,775fr) 159px"
          lgY="306fr minmax(0px, max-content) 1fr"
          lgDisplay="show"
          mdX="986fr minmax(400px,775fr) 80px"
          mdY="306fr minmax(0px, max-content) 1fr"
          mdDisplay="none"
        />) : (
        <Px.img
          data-src={`${props.indexdesigner2}`}
          x="4fr 8fr 1fr"
          y="306fr minmax(0px, max-content) 1fr"
          absolute
          className={`${css(styles.indexdesigner2)} swiper-lazy`}
          lgX="4fr 8fr 1fr"
          lgY="306fr minmax(0px, max-content) 1fr"
          lgDisplay="show"
          mdX="4fr 8fr 1fr"
          mdY="306fr minmax(0px, max-content) 1fr"
          mdDisplay="none"
        />)}
      <Px.img
        data-src={`${props.indexdesigner2}`}
        x="572.5fr 775fr 572.5px"
        y="306fr minmax(0px, max-content) 1fr"
        absolute
        className={`${css(styles.indexdesigner2)} swiper-lazy`}
        maxX="572.5fr 775fr 572.5px"
        maxY="306fr minmax(0px, max-content) 1fr"
        maxDisplay="none"
        lgX="572.5fr minmax(400px,775fr) 572.5px"
        lgY="306fr minmax(0px, max-content) 1fr"
        lgDisplay="none"
        mdX="572.5fr minmax(400px,775fr) 50px"
        mdY="306fr minmax(0px, max-content) 1fr"
        mdDisplay="show"
      />

      <Px.div
        x="799fr 373px 748fr"
        y="466fr minmax(0px, max-content) 399fr"
        className={css(styles.flex)}
        xxlX="799fr 373px 748fr"
        xxlY="233fr minmax(0px, max-content) 200fr"
        xlX="799fr 307px 748fr"
        xlY="117fr minmax(0px, max-content) 100fr"
        lgX="700fr 307px 748fr"
        lgY="59fr minmax(0px, max-content) 50fr"
        mdX="700fr 307px 748fr"
        mdY="30fr minmax(0px, max-content) 25fr"
        smX="700fr 307px 748fr"
        smY="25fr minmax(0px, max-content) 25fr"
        xsX="700fr 280px 748fr"
        xsY="25fr minmax(0px, max-content) 25fr"
      >
        <div className={css(styles.group1, styles.group1_layout)}>
          <div className={css(styles.labeleg_box, styles.labeleg_box_layout)}>
            <div
              style={{ '--color': props.label }}
              className={css(styles.label, styles.label_layout)}
            />
            <div className={css(styles.labeleg)}>{props.labeleg}</div>
          </div>
        </div>

        <h3 className={css(styles.labelct_box, styles.labelct_box_layout)}>
          <pre className={css(styles.labelct)}>{props.labelct}</pre>
        </h3>
        <h1 className={css(styles.title, styles.title_layout)}>
          {props.title}
        </h1>
        <h5 className={css(styles.description, styles.description_layout)}>
          {props.description}
        </h5>
      </Px.div>
    </div>
  );
});

Promo_005.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  indexdesignerbg: {
    background: 'center center / cover no-repeat',
    width: '100%',
    height: '100%'
  },
  indexdesigner1: {
    width: '100%',
    height: 'auto'
  },
  indexdesigner2: {
    width: '100%',
    height: 'auto'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    overflow: 'visible',
    minHeight: 14,
    margin: 0
  },
  label: {
    backgroundColor: 'var(--color)'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: -2,
    width: '100%'
  },
  labeleg: {
    position: 'relative',
    overflow: 'visible',
    zIndex: 2,
    marginTop: 0,
    marginBottom: 0,
    font: '900 12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 1199.98px)': {
      fontSize: '10px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      fontSize: '7px',
      textAlign: 'left'
    }
  },
  labeleg_box: {
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labeleg_box_layout: {
    position: 'relative'
  },
  labelct: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 20px/1 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 1199.98px)': {
      fontSize: '18px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      fontSize: '15px',
      textAlign: 'left'
    }
  },
  labelct_box: {
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labelct_box_layout: {
    position: 'relative',
    margin: '1px 0px 0px'
  },
  title: {
    font: '900 32px/1.375 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    letterSpacing: '2px',
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '28px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '26px',
      textAlign: 'left'
    },
    '@media (max-width: 767.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '27px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 0px 0px'
  },
  description: {
    font: '16px/1.875 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    letterSpacing: '1px',
    margin: 0,
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '14px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '13px',
      textAlign: 'left'
    }
  },
  description_layout: {
    position: 'relative',
    margin: '11px 0px 0px'
  }
});

export default Promo_005;
