import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import ProtalModal from '../ProtalModal';

@observer
class CustomizeModal extends React.Component {
  render() {
    return (
      <ProtalModal shouldIgnorePuppeteer={this.props.shouldIgnorePuppeteer}>
        <div className={this.props.className} onClick={this.props.hideModal}>
          <div
            className={styles.modalBg}
            style={{
              background: this.props.bgColor ?? '#000'
            }}
          />
          {this.props.children}
        </div>
      </ProtalModal>
    );
  }
}

CustomizeModal.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  hideModal: PropTypes.func,
  bgColor: PropTypes.string,
  shouldIgnorePuppeteer: PropTypes.bool
};

CustomizeModal.defaultProps = {
  children: null,
  className: null,
  hideModal: null,
  bgColor: null,
  shouldIgnorePuppeteer: false
};

export default CustomizeModal;
