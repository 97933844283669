import CustomError from 'src/models/CustomError';

export default class ErrorMessage {
  static signup(error) {
    switch (error.response?.status) {
      case 400:
        return '註冊內容填寫錯誤，請重新檢視';
      case 403:
        return '此帳號已被停權';
      case 409:
        return '此帳號已註冊，請重新輸入或前往登入';
      default:
        return CustomError.fromError(error);
    }
  }

  static login(error) {
    switch (error.response?.status) {
      case 400:
      case 401:
        return '驗證碼錯誤，請重新輸入';
      case 403:
        return '驗證碼錯誤，請重新輸入';
      case 404:
        return '帳號或驗證碼輸入錯誤，請重新檢視';
      default:
        return CustomError.fromError(error);
    }
  }

  static otp(error) {
    switch (error.response?.status) {
      case 400:
        return '帳號填寫錯誤，請重新檢視';
      case 403:
        return '此帳號已被停權';
      case 404:
        return '此帳號不存在，請重新輸入或前往註冊';
      case 429:
        return '操作太頻繁，請稍後再試';
      default:
        return CustomError.fromError(error);
    }
  }

  static createEcPayForm(error) {
    switch (error.response?.status) {
      case 401:
        return '您尚未登入，請登入後再查看';
      case 403:
        return '此帳號已被停權';
      case 406:
        return '該物件已下架、額滿或售出，請查看其他物件或聯繫客服';
      case 412:
        return '價格發生變動，請重試';
      default:
        return CustomError.fromError(error);
    }
  }

  static getOrder(error) {
    switch (error.response?.status) {
      case 400:
        return '付款人資訊填寫有誤，請重新檢視';
      case 401:
        return '您尚未登入，請登入後再查看';
      case 403:
        return '此帳號已被停權';
      case 404:
        return '目前查無此訂單，請稍後再試或聯繫客服';
      default:
        return CustomError.fromError(error);
    }
  }

  static getPreOrder(error) {
    switch (error.response?.status) {
      case 400:
        return '資訊填寫有誤，請重新檢視';
      case 401:
        return '您尚未登入，請登入後再查看';
      case 403:
        return '此帳號已被停權';
      case 404:
        return '查無此筆資料，請稍後再試或聯繫客服';
      default:
        return CustomError.fromError(error);
    }
  }

  static createPreOrder(error) {
    switch (error.response?.status) {
      case 400:
        return '資訊填寫有誤，請重新檢視';
      case 401:
        return '您尚未登入，請登入後再查看';
      case 403:
        return '此帳號已被停權';
      case 404:
        return '查無此筆資料，請稍後再試或聯繫客服';
      case 412:
        return '查無資訊，此物件可能已下架或售出，請選購其他物件或洽客服人員。';
      default:
        return CustomError.fromError(error);
    }
  }

  static updatePreOrder(error) {
    switch (error.response?.status) {
      case 400:
        return '資訊填寫有誤，請重新檢視';
      case 401:
        return '您尚未登入，請登入後再查看';
      case 403:
        return '此帳號已被停權';
      case 404:
        return '查無此筆資料，請稍後再試或聯繫客服';
      default:
        return CustomError.fromError(error);
    }
  }

  static createNewebpayForm(error) {
    switch (error.response?.status) {
      case 401:
        return '您尚未登入，請登入後再查看';
      case 403:
        return '此帳號已被停權';
      case 406:
        return '該物件已下架、額滿或售出，請查看其他物件或聯繫客服';
      case 412:
        return '價格發生變動，請重試';
      default:
        return CustomError.fromError(error);
    }
  }
}
