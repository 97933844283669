import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import UserService from 'src/services/user';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';

function useProfile(isRequired = true) {
  const { state, actions } = useAppStateContext();
  const history = useHistory();

  const { updateProfile } = actions ?? {};

  useEffect(
    () => {
      const update = async () => {
        try {
          const p = await UserService.getMyProfile();
          updateProfile(p);

        } catch (err) {

          updateProfile(null);
          UserService.logout();

          if (isRequired) {
            // TODO: change some here.
            history.replace('/login', { target: this.props.location?.pathname });
          }
        }
      };

      update();
    },
    [history, updateProfile, isRequired]
  );

  return state.profile;
}

export default useProfile;
