import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import validator from 'validator';
import Plans from 'src/services/plans';
import { v4 as uuidv4 } from 'uuid';

class GuestCSForm {
  @observable isOpen = false;

  @observable guestLastName = '';
  @observable guestFirstName = '';
  @observable guestEmail = '';

  @observable isCheck = false;

  @observable planId = '';
  // NOTE: check for guest sales choose after close.
  @observable isGuestLogin = false;

  constructor() {
    makeObservable(this);
  }

  @action
  didMount = async () => {
    await this.getPlanId();
  }

  @action
  getPlanId = async () => {
    try {
      const res = await Plans.getPlanInfo();
      runInAction(() => {
        this.planId = res.id;
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action
  toggleOpen = () => {
    this.isOpen = !this.isOpen;
  }

  @action
  onLastNameChange = (e) => {
    this.guestLastName = e.target.value;
  }

  @action
  onFirstNameChange = (e) => {
    this.guestFirstName = e.target.value;
  }

  @action
  onEmailChange = (e) => {
    this.guestEmail = e.target.value;
  }

  @computed
  get isLastNameIllegal() {
    return this.isCheck && !this.guestLastName.trim();
  }

  @computed
  get isFirstNameIllegal() {
    return this.isCheck && !this.guestFirstName.trim();
  }

  @action
  onSubmit = async (startCSFunc, openModalFunction) => {
    try {
      this.isCheck = true;
      this.guestLastName = this.guestLastName.trim();
      this.guestFirstName = this.guestFirstName.trim();

      if (this.isLastNameIllegal || this.isFirstNameIllegal || !this.isEmailLegal) {
        return;
      }

      await startCSFunc(`guest_${this.planId}#${this.guestLastName}${this.guestFirstName}#${this.guestEmail}#${uuidv4()}`);
      this.isGuestLogin = true;
      this.toggleOpen();
      openModalFunction();
    } catch (error) {
      console.log(error);
    }
  }

  @action
  guestLogout = () => {
    this.isGuestLogin = false;
    this.cleanInfo();
  }

  @action
  cleanInfo = () => {
    this.guestLastName = '';
    this.guestFirstName = '';
    this.guestEmail = '';
  }

  @computed
  get isEmailLegal() {
    if (!this.isCheck || !this.guestEmail.trim()) {
      return true;
    }
    return validator.isEmail(this.guestEmail);
  }
}

const guestCSForm = new GuestCSForm();

export default guestCSForm;
