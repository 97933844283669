
export default class CustomError extends Error {
  _type = 'CustomError';

  constructor(message) {
    super(message);

    this.message = message ?? '';
  }

  toString = () => this.message;

  static isCustomError(err) {
    return err._type === 'CustomError';
  }

  static fromError(err) {
    if (err.response) {
      return new CustomError(`${err.response.status} ${err.response.data?.message}`);
    }
    return new CustomError(err?.message ?? err?.stack ?? err);
  }
}
