import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import isMobile from 'ismobilejs';

import styles from './styles.module.css';

const NotFoundPage = (props) => {
  const history = useHistory();

  const mobile = isMobile(window.navigator).any;

  return (
    <div className={styles.page}>
      <div
        className={clsx(styles.img, mobile && styles.mobile)}
        onClick={() => history.replace('/')}
      />
    </div>
  );
};

export default NotFoundPage;
