export default class Model {
  constructor(params) {
    this.deserialize(params);
  }

  deserialize = (params) => {
    const {
      type,
      image,
      title,
      description
    } = params;

    this.type = type;
    this.title = title;
    this.image = image;
    this.description = description;
  }
}
