import { SectionType } from 'src/constants';
import SectionA from './SectionAViewModel';
import SectionB1 from './SectionB1ViewModel';
import SectionB2 from './SectionB2ViewModel';
import SectionB3 from './SectionB3VIewModel';
import SectionC from './SectionCViewModel';
import SectionD from './SectionDViewModel';
import SectionE from './SectionEViewModel';
import SectionF from './SectionFViewModel';
import SectionG from './SectionGViewModel';
import SectionH from './SectionHViewModel';


const createSectionViewModel = (data) => {
  const { type, ...others } = data ?? {};
  switch (type?.toLowerCase()) {
    case SectionType.A:
      return new SectionA(others);
    case SectionType.B1:
      return new SectionB1(others);
    case SectionType.B2:
      return new SectionB2(others);
    case SectionType.B3:
      return new SectionB3(others);
    case SectionType.C:
      return new SectionC(others);
    case SectionType.D:
      return new SectionD(others);
    case SectionType.E:
      return new SectionE(others);
    case SectionType.F:
      return new SectionF(others);
    case SectionType.G:
      return new SectionG(others);
    case SectionType.H:
      return new SectionH(others);
    default:
      throw new Error(`unsupported section type: ${type}`);
  }
};

export { createSectionViewModel };
