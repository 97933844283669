import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet } from '@react-pdf/renderer';

const LatReg = /3d(\d+(\.\d+)?)!/i;
const LngReg = /2d(\d+(\.\d+)?)!/i;
const Zoom = 16;
const Width = 1000;
const Height = 650;

const Map = (props) => {
  if (!props.url) {
    return null;
  }

  const lat = props.url.match(LatReg)?.[1];
  const lng = props.url.match(LngReg)?.[1];

  return (
    <Image
      style={styles.map}
      source={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${Zoom}&scale=false&size=${Width}x${Height}&maptype=roadmap&key=AIzaSyBcvrTS06O0LlwXAyfUrDmwPocIj8RQzsg&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${lat},${lng}`}
    />
  );
};

Map.defaultProps = {
  url: null
};

Map.propTypes = {
  url: PropTypes.string
};

const styles = StyleSheet.create({
  map: {
    // width: 500,
    height: 325,
    objectFit: 'cover'
  }
});

export default Map;
