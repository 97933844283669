import { observable, action, makeObservable } from 'mobx';

import { SectionType } from 'src/constants';
import CustomizeCarouselViewModel from 'src/viewModels/Advertisement/CustomizeCarousel';
import ISection from './ISection';

export default class SectionEViewModel extends ISection {
  @observable id;
  @observable titleEg;
  @observable titleCt;
  @observable indexnewsbg;

  get type() {
    return SectionType.E;
  }

  constructor(params) {
    super(params);
    makeObservable(this);

    this.deserialize(params);
    const slides = params.news;
    this.carouselViewModel = new CustomizeCarouselViewModel(slides);
  }

  onEnter = () => {};

  onLeave = () => {};

  @action
  deserialize = (params) => {
    const {
      id,
      titleEg,
      titleCt,
      indexnewsbg
    } = params ?? {};

    this.id = id;
    this.titleEg = titleEg;
    this.titleCt = titleCt;
    this.indexnewsbg = indexnewsbg;
  };
}
