import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  View,
  StyleSheet
} from '@react-pdf/renderer';

import FooterImage1 from 'src/assets/receipt_footer1.png';
import FooterImage2 from 'src/assets/receipt_footer2.png';

const Footer = (props) => {
  return (
    <View style={styles.footer}>
      <View style={styles.separator} />

      <Image source={FooterImage1} />
      <Image source={FooterImage2} />
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {

  },
  separator: {
    height: 1,
    backgroundColor: '#C4C4C4',
    margin: '50px 40px 50px 40px'
  }
});

export default Footer;
