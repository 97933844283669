import { css, StyleSheet } from 'aphrodite/no-important';

// version v1.00.1

export const config = {
  hashName: true,
  debug: false,

  StyleSheet,
  css
};
