export default class Model {
  constructor(params) {
    this.deserialize(params);
  }

  deserialize = (params) => {
    const {
      image,
      type,
      imgSize
    } = params;

    this.image = image;
    this.type = type;
    this.imgSize = imgSize; // 參考 css object-fit 屬性
  }
}
