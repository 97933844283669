export const Action = {
  UpdateProfile: 'UpdateProfile',
  UpdatePlan: 'UpdatePlan',
  ShowModal: 'ShowModal'
};

/**
 *  @param modal = {
 *    theme: Theme.warning,
 *    image: '', // public 圖片路徑，可有可無
 *    title: 'INFO',
 *    content: '您即將刪除此筆資料\n請確認是否執行',
 *    cancelText: '先不要',
 *    okText: '刪除',
 *    onOk: () => {},
 *    onCancel: () => {}
 *  }
 */
export const actions = (dispatch) => ({
  updateProfile: (profile) => dispatch({
    type: Action.UpdateProfile,
    payload: profile
  }),
  updatePlan: (plan) => dispatch({
    type: Action.UpdatePlan,
    payload: plan
  }),
  showModal: (modal) => dispatch({
    type: Action.ShowModal,
    payload: modal
  })
});
