import Area from './Area';
import Label from './Label';

export default class Space {
  constructor(params) {
    const {
      id,
      name,
      status,
      isActive,
      price,
      announcement,
      description,
      labels,
      garagesSelected,
      area,
      preordersCount
    } = params ?? {};

    this.id = id;
    this.name = name;
    this.status = status;
    this.isActive = isActive;
    this.price = price;
    this.announcement = announcement;
    this.description = description;
    this.labels = labels?.map((label) => Label.fromRes(label)) ?? [];
    this.garagesSelected = garagesSelected;
    this.area = Area.fromRes(area);
    this.preordersCount = preordersCount;
  }

  get isDisable() {
    return this.status === 'reserved' || this.status === 'ordered';
  }

  static fromRes(data) {
    return new Space(data);
  }
}
