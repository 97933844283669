import React, { forwardRef } from 'react';
import useAppStateContext from './useAppStateContext';


const withAppStateContext = (WrappedComponent) => forwardRef((props, ref) => {
  const context = useAppStateContext();
  return <WrappedComponent {...props} ref={ref} appContext={context} />;
});

export default withAppStateContext;


// https://stackoverflow.com/a/57783573/16388039
