/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Iframe from 'src/components/Iframe';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';

import arrowImg from '../../../../../assets/goShoppingArrow.png';

export default function P012(props) {
  return (
    <div
      className={`p012 ${css(styles.content_box, styles.content_box_layout)}`}
    >
      <div className={css(styles.content_box_spacer)} />
      <Px.h1
        x="24px 380fr 24px"
        y="1fr minmax(0px, max-content) 1fr"
        className={css(styles.infoCase)}
        xsX="24px 380fr 24px"
        xsY="1fr minmax(0px, max-content) 10px"
      >
        {props.infoCase}
      </Px.h1>

      <Px.div
        x="24px 380fr 24px"
        y="21fr minmax(0px, max-content) 0px"
        className={css(styles.flex)}
        xsX="24px 380fr 24px"
        xsY="21fr minmax(0px, max-content) 2px"
      >
        <h5 className={css(styles.info, styles.info_layout)}>{props.info}</h5>
        <div className={css(styles.flex_spacer)} />
        <div className={css(styles.infoeng_box, styles.infoeng_box_layout)}>
          <div className={css(styles.infoeng)}>
            {`${props.infoeng}/`}
          </div>
        </div>
      </Px.div>

      <div className={css(styles.line, styles.line_layout)} />
      <div className={css(styles.content_box_row)}>
        <div className={css(styles.infoSubTit, styles.infoSubTit_layout)}>
          <Px
            x="0px 276px 1fr"
            y="0px minmax(0px, 201fr) 9px"
            absolute
            className={css(styles.paragraph_body_box)}
            onTouchStart={() => props.swiper.disable()}
            onTouchEnd={() => props.swiper.enable()}
          >
            <pre
              className={css(styles.paragraph_body)}

            >
              {props.infoSubTit1 + props.infoSubTit2}
            </pre>
          </Px>
        </div>
      </div>
      <div className={css(styles.content_box_row1)}>
        <div className={css(styles.map, styles.map_layout)}>
          <Iframe value={props.map} isGrayScale />
        </div>
      </div>

      {/* <div className={css(styles.content_box_row2)}>
        <Link
          to="/pickout"
          className={css(styles.cover_group, styles.cover_group_layout)}
          style={{
            borderRadius: props.shoppingbutton.curveFillet,
            backgroundColor: props.shoppingbutton.color
          }}
        >
          <div className={css(styles.flex1, styles.flex1_layout)}>
            <h4 className={css(styles.highlights, styles.highlights_layout)}>
              {props.shoppingbutton.text}
            </h4>
            <div className={css(styles.flex1_spacer)} />
            <div
              style={{
                '--src': `url(${arrowImg})`
              }}
              className={css(styles.img, styles.img_layout)}
            />
          </div>
        </Link>
      </div> */}
      <div className={css(styles.content_box_spacer)} />
    </div>
  );
}

P012.inStorybook = true;

const styles = StyleSheet.create({
  content_box: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(255,255,255)'
  },
  content_box_layout: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  content_box_spacer: {
    display: 'flex',
    flex: '1 1 110px'
  },
  infoCase: {
    font: '40px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    width: '100%',
    height: '100%'
  },
  flex: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  info: {
    font: '15px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px'
  },
  info_layout: {
    position: 'relative',
    margin: '0px 0px 2px'
  },
  flex_spacer: {
    display: 'flex',
    flex: '0 1 10px'
  },
  infoeng: {
    font: '100 14px "Roboto", "Noto Sans TC", serif',
    color: '#282828',
    letterSpacing: '0.68px'
  },
  infoeng_box: {},
  infoeng_box_layout: {
    position: 'relative',
    margin: '1px 0px 0px'
  },
  line: {
    backgroundColor: '#EBEBEB'
  },
  line_layout: {
    position: 'relative',
    height: 1,
    margin: '12px 24px 0px',
    '@media (max-width: 575.98px)': {
      height: 1,
      margin: '5px 24px 0px'
    }
  },
  content_box_row: {
    display: 'flex',
    flex: '1 0 170px'
  },
  infoSubTit: {
    display: 'flex'
  },
  infoSubTit_layout: {
    position: 'relative',
    overflow: 'scroll',
    flexGrow: 1,
    margin: '2px 24px'
  },
  paragraph_body: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '13px/2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap'
  },
  paragraph_body_box: {
    width: '100%',
    height: '100%'
  },
  content_box_row1: {
    display: 'flex',
    flex: '2 0 180px',
    margin: '0 0 30px'
  },
  map: {
    width: '100%',
    height: '100%'
  },
  map_layout: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    width: 'calc(100% - 40px - 24px)',
    margin: '11px 40px 0px 24px',
    '@media (max-width: 575.98px)': {
      height: '100%',
      flexGrow: 1,
      width: 'calc(100% - 24px - 40px)',
      margin: '10px 40px 0px 24px'
    }
  },
  content_box_row2: {
    display: 'flex'
  },
  cover_group: {
    display: 'flex',
    backgroundColor: 'rgb(41,41,41)',
    alignItems: 'center'
  },
  cover_group_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    margin: '0 auto ',
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '0 auto '
    }
  },
  flex1: {
    display: 'flex',
    alignItems: 'center'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: '12px 26.5px',
    '@media (max-width: 575.98px)': {
      flexGrow: 1,
      margin: '12px 13px'
    }
  },
  highlights: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: '18px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    letterSpacing: '5.625px',
    '@media (max-width: 575.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '15px',
      textAlign: 'center'
    }
  },
  highlights_layout: {
    position: 'relative',
    flex: '0 1 185px',
    width: 185,
    minWidth: 185,
    margin: 0
  },
  flex1_spacer: {
    display: 'flex',
    flex: '0 1 0.5px'
  },
  img: {
    background: 'var(--src) center center / contain no-repeat'
  },
  img_layout: {
    position: 'relative',
    flex: '0 1 52.5px',
    height: 8.68,
    width: 52.5,
    minWidth: 52.5
  },
  content_box_row3: {
    display: 'flex',
    flex: '0 1 90px'
  },
  img1: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  }
});

P012.propTypes = {
  infoCase: PropTypes.string,
  info: PropTypes.string,
  infoeng: PropTypes.string,
  infoSubTit1: PropTypes.string,
  infoSubTit2: PropTypes.string,
  // shoppingbutton: PropTypes.object,
  swiper: PropTypes.object,
  map: PropTypes.string
};

P012.defaultProps = {
  infoCase: null,
  info: null,
  infoeng: null,
  infoSubTit1: null,
  infoSubTit2: null,
  // shoppingbutton: null,
  swiper: null,
  map: null
};
