import React from 'react';
import { Card, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import soldIcon from 'src/assets/center_sold.png';
import timeIcon from 'src/assets/center_time.png';
import downloadIcon from 'src/assets/center_download.png';

import styles from './styles.module.css';
import { Status } from './model';

function ShoppingItem({
  name,
  item,
  onClick,
  onDelete,
  onDownload,
  nowDownLoadingID,
  disabled,
  displayType
}) {
  // 0: flex, 1: grid
  return displayType ? (
    <Card
      hoverable
      bordered={false}
      onClick={disabled ? null : onClick}
      className={styles.shopping_card}
      bodyStyle={{ padding: '3px 20px 20px 20px' }}
      cover={(
        <div className={styles.card_cover}>
          <img src={item.img} className={styles.card_img} alt="shopping_item" style={{ paddingTop: 10, paddingLeft: 10 }} />
        </div>
      )}
    >
      <div className={styles.card_title_layout}>
        <span className={styles.card_title}>{item.household}</span>

        <div className={styles.card_tag}>
          <span className={styles.tag_text}>{item.tag}</span>
        </div>
        {item.orderType === Status.sold && (
          <img src={soldIcon} width={25} height={25} style={{ marginLeft: 5 }} alt="sold" />
        )}

      </div>

      <div className={styles.detail_container}>
        <div className={styles.household}>{item.remarks[0]}</div>

        <div className={styles.household}>{item.remarks[1]}</div>

        <div className={styles.household}>{item.remarks[2]}</div>

        <div className={styles.household}>
          {`房屋總價${item.formatHouseholdPrice}`}
        </div>

        <div className={styles.household}>
          {`車位總價${item.formatSpacesPrice}`}
        </div>
      </div>

      <div className={styles.price}>
        訂單總價:
        {item.formatTotalPrice}
      </div>

      <div className={styles.time} style={{ marginBottom: 5 }}>
        <img src={timeIcon} width={10} height={10} alt="time" />
        <span className={styles.time_text}>{item.updateTime}</span>
      </div>

      {item.type === 'order' && (
        <div className={styles.oid}>
          訂單編號：
          {item.id}
        </div>
      )}

      {onDownload && (
        <div
          className={clsx(styles.download, disabled && styles.disable)}
          onClick={disabled ? null : onDownload}
        >
          <span className={styles.download_text}>下載訂單</span>
          {nowDownLoadingID === item.id && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
            />
          )}
        </div>
      )}

      {onDelete && (
        <div
          className={styles.remove_text}
          onClick={disabled ? null : onDelete}
        >
          移除
        </div>
      )}
    </Card>
  ) : (
    <Card
      hoverable
      bordered={false}
      onClick={disabled ? null : onClick}
      className={styles.shopping_card_flex}
      bodyStyle={{ padding: '0px 0px 0px 0px' }}
      cover={(
        <div className={clsx(styles.card_cover)}>
          <img src={item.img} className={styles.card_img} alt="shopping_item" style={{ marginRight: 15 }} />

          {onDelete && (
            <div
              className={styles.remove_text}
              onClick={disabled ? null : onDelete}
            >
              移除
            </div>
          )}

          {onDownload && (
            <div
              className={clsx(styles.download, disabled && styles.disable)}
              onClick={disabled ? null : onDownload}
            >
              <span className={styles.download_text}>下載訂單</span>
              {nowDownLoadingID === item.id && (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
                />
              )}
            </div>
          )}
        </div>
      )}
    >
      <div className={styles.card_title_layout}>
        <div className={styles.card_title}>{item.household}</div>

        <div className={styles.card_tag}>
          <span className={styles.tag_text}>{item.tag}</span>
        </div>
        {item.orderType === Status.sold && (
          <img src={soldIcon} width={25} height={25} style={{ marginLeft: 5 }} alt="sold" />
        )}

      </div>

      <div className={styles.detail_container}>
        <div className={styles.household}>{item.remarks[0]}</div>

        <div className={styles.household}>{item.remarks[1]}</div>

        <div className={styles.household}>{item.remarks[2]}</div>

        <div className={styles.household}>
          {`房屋總價${item.formatHouseholdPrice}`}
        </div>

        <div className={styles.household}>
          {`車位總價${item.formatSpacesPrice}`}
        </div>
      </div>


      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles.price}>
          訂單總價:
          {item.formatTotalPrice}
        </div>

        <div className={styles.time}>
          <img src={timeIcon} width={10} height={10} alt="time" />
          <span className={styles.time_text}>{item.updateTime}</span>
        </div>
      </div>




      {item.type === 'order' && (
        <div className={styles.oid}>
          訂單編號：
          {item.id}
        </div>
      )}
    </Card>
  );
}

ShoppingItem.defaultProps = {
  item: {},
  onDelete: null,
  onDownload: null,
  onClick: null,
  name: '',
  disabled: false,
  nowDownLoadingID: 0,
  displayType: 0
};

ShoppingItem.propTypes = {
  item: PropTypes.object,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  nowDownLoadingID: PropTypes.number,
  displayType: PropTypes.number
};

export default ShoppingItem;
