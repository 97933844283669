/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';

export default function C007(props) {
  return (
    <div
      className={`c007 ${css(styles.content_box, styles.content_box_layout)}`}
    >
      <div className={css(styles.content_box_spacer)} />
      <div className={css(styles.content_box_col)}>
        <Px.div
          data-background={props.image}
          x="0px 50px 0px"
          y="5fr 50px 5fr"
          className={`${css(styles.image)} swiper-lazy`}
        />
      </div>
      <div className={css(styles.content_box_spacer)} />
      <div className={css(styles.content_box_col1)}>
        <Px
          x="0px 101fr 19fr"
          y="14fr minmax(0px, max-content) 16fr"
          className={css(styles.paragraph_body_box)}
        >
          <pre className={css(styles.paragraph_body)}>
            {props.paragraph_body}
          </pre>
        </Px>
      </div>
    </div>
  );
}

C007.inStorybook = true;

const styles = StyleSheet.create({
  content_box: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  content_box_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    margin: 0
  },
  content_box_spacer: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 5
  },
  content_box_col: {
    display: 'flex',
    flex: '0 1 50px'
  },
  image: {
    background: 'center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  content_box_col1: {
    display: 'flex',
    flex: '1 0 120px'
  },
  paragraph_body: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
    font: '13px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap'
  },
  paragraph_body_box: {
    width: '100%',
    height: '100%'
  }
});

C007.propTypes = {
  image: PropTypes.string,
  paragraph_body: PropTypes.string
};

C007.defaultProps = {
  image: null,
  paragraph_body: null
};
