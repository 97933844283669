import React from 'react';

import { SectionType } from 'src/constants';
import SectionA from './SectionA';
import SectionB1 from './SectionB1';
import SectionB2 from './SectionB2';
import SectionB3 from './SectionB3';
import SectionC from './SectionC';
import SectionD from './SectionD';
import SectionE from './SectionE';
import SectionF from './SectionF';
import SectionG from './SectionG';
import SectionH from './SectionH';

function createSectionView(viewModel) {
  const { type } = viewModel;

  if (viewModel) {
    switch (type) {
      case SectionType.A:
        return <SectionA viewModel={viewModel} />;
      case SectionType.B1:
        return <SectionB1 viewModel={viewModel} />;
      case SectionType.B2:
        return <SectionB2 viewModel={viewModel} />;
      case SectionType.B3:
        return <SectionB3 viewModel={viewModel} />;
      case SectionType.C:
        return <SectionC viewModel={viewModel} />;
      case SectionType.D:
        return <SectionD viewModel={viewModel} />;
      case SectionType.E:
        return <SectionE viewModel={viewModel} />;
      case SectionType.F:
        return <SectionF viewModel={viewModel} />;
      case SectionType.G:
        return <SectionG viewModel={viewModel} />;
      case SectionType.H:
        return <SectionH viewModel={viewModel} />;
      default:
        throw new Error(`unsupported section type: ${type}`);
    }
  } else {
    return null;
  }
}

export { createSectionView };
