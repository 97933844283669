import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { useForm } from 'react-hook-form';
import PhoneInput from 'src/components/Common/PhoneInput';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import CommButton from 'src/components/Common/Button';
import CheckIcon from 'src/assets/otp_check.png';
import { phoneRule } from 'src/constants';
import styles from './styles.module.css';

export default function LoginForm({ onInputChange, onSubmit, loading, verifyClick, onTimeUp, signUpToken }) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row} style={{ marginTop: 37, marginBottom: 5 }}>
        <div className={styles.form_title}>
          手機號碼
          <br />
          請輸入您的手機號碼，以接收驗證碼，完成登入/註冊手續。
        </div>
      </div>

      <div className={errors.phone ? styles.inputErrorContainer : styles.inputContainer}>
        <div className={styles.phone}>
          <PhoneInput
            control={control}
            name="phone"
            rules={{
              validate: (value) => phoneRule(value)
            }}
            placeholder="請輸入手機號碼"
            className={errors.phone}
            maxLength={10}
            disabled={signUpToken}
            onInputChange={onInputChange}
          />
        </div>

        {
          !signUpToken ? (
            <>
              { !verifyClick ? (
                <Button
                  className={styles.verify}
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                >
                  驗證
                </Button>
              ) : (
                <CommButton
                  className={styles.resend}
                  // disabled={seconds > 0}
                  onClick={onTimeUp}
                >
                  重新發送
                </CommButton>
              )}
            </>
          ) : (
            <div className={styles.otpCheck}>
              <img src={CheckIcon} height="100%" alt="check" />
            </div>
          )
        }

      </div>
      <div className={styles.row}>
        <ErrorMessage error={errors.phone} label="手機號碼輸入錯誤" />
      </div>
    </form>
  );
}

LoginForm.defaultProps = {
  onInputChange: () => {},
  onSubmit: () => {},
  loading: false,
  verifyClick: false,
  signUpToken: null,
  onTimeUp: () => {}
};

LoginForm.propTypes = {
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  verifyClick: PropTypes.bool,
  onTimeUp: PropTypes.func,
  signUpToken: PropTypes.string
};
