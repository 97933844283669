/* eslint-disable react/jsx-pascal-case */
/**
 * This source code is exported from pxCode, you can get more document from https://www.pxcode.io
 */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';

export default function P002(props) {
  return (
    <div className={`p002 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="0px 428fr 0px"
        y="130fr minmax(0px, max-content) 130fr"
        className={css(styles.flex)}
      >
        <div className={css(styles.slogan, styles.slogan_layout)}>
          {props.slogan}
        </div>
        <div className={css(styles.carousel)}>
          {props.carousel}
          {props._renderPreBtn}
          {props._renderNextBtn}
        </div>

        <div className={css(styles.flex1, styles.flex1_layout)}>
          <div className={css(styles.group1, styles.group1_layout)}>
            <div className={css(styles.labeleg_box, styles.labeleg_box_layout)}>
              <pre className={css(styles.labeleg)}>{props.labeleg}</pre>
              <div
                style={{ '--color': props.label }}
                className={css(styles.label, styles.label_layout)}
              />
            </div>
          </div>

          <h3 className={css(styles.labelct_box, styles.labelct_box_layout)}>
            <pre className={css(styles.labelct)}>{props.labelct}</pre>
          </h3>
          <h1 className={css(styles.title, styles.title_layout)}>
            {props.title}
          </h1>
          <h5 className={css(styles.description, styles.description_layout)}>
            {props.description}
          </h5>
        </div>
      </Px.div>
    </div>
  );
}

P002.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  slogan: {
    display: 'flex',
    justifyContent: 'center',
    font: '100 14px/2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    margin: 0,
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  slogan_layout: {
    position: 'relative',
    width: '60.51%',
    minWidth: 'min-content',
    margin: '0px auto 20px'
  },
  carousel: {
    position: 'relative',
    width: '95%',
    paddingTop: '75%'
  },
  flex1: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '38px 35px 31px 20px'
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: 0
  },
  label: {
    backgroundColor: 'var(--color)'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: -1,
    left: 0,
    width: '100%',
    zIndex: 0
  },
  labeleg: {
    position: 'relative',
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 12px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '10px',
      textAlign: 'left'
    },
    zIndex: 1
  },
  labeleg_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labeleg_box_layout: {
    position: 'relative',
    margin: 0
  },
  labelct: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 20px/1 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '17px',
      textAlign: 'left'
    }
  },
  labelct_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labelct_box_layout: {
    position: 'relative',
    margin: '1px 0px 0px'
  },
  title: {
    font: '900 32px/1.375 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '26px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 0px 0px'
  },
  description: {
    font: '16px/1.875 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    margin: 0,
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '12px',
      textAlign: 'left'
    }
  },
  description_layout: {
    position: 'relative',
    margin: '11px 0px 0px'
  }
});

P002.propTypes = {
  slogan: PropTypes.string,
  carousel: PropTypes.element,
  _renderNextBtn: PropTypes.element,
  _renderPreBtn: PropTypes.element,
  label: PropTypes.string,
  labeleg: PropTypes.string,
  labelct: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};

P002.defaultProps = {
  slogan: null,
  carousel: null,
  _renderNextBtn: null,
  _renderPreBtn: null,
  label: null,
  labeleg: null,
  labelct: null,
  title: null,
  description: null
};
