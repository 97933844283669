import React from 'react';
import { observable, makeObservable, action, runInAction } from 'mobx';

import Plans from 'src/services/plans';
import UserService from 'src/services/user';
import CustomizeModalViewModel from 'src/viewModels/CustomizeModal';

class SalesPopup extends CustomizeModalViewModel {
  @observable salesList = [];
  @observable planId = 0;
  @observable isShowBox = false;

  @observable status = {
    beforeLoaded: true
  }

  constructor(props) {
    super(props);

    makeObservable(this);
  }

  @action
  changeShow = () => {
    this.isShowBox = !this.isShowBox;
  }

  @action
  didUpdate = async () => {
    try {
      if (this.status.beforeLoaded) {
        await this.init();
        runInAction(() => {
          this.status.beforeLoaded = false;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action
  getPlanId = async () => {
    try {
      const res = await Plans.getPlanInfo();
      this.planId = res.id;

    } catch (error) {
      console.error(error);
    }
  }

  @action
  getSalesList = async () => {
    try {
      const res = await UserService.getSales();
      const salesList = res.map((sales) => ({
        id: sales.id,
        name: sales.lastName + sales.firstName,
        avatar: sales.avatar,
        status: sales.status,
        chatId: `${this.planId}@${sales.id}`
      }));
      this.salesList = salesList;
    } catch (error) {
      console.error(error);
    }
  }

  @action
  init = async () => {
    try {
      await this.getPlanId();
      await this.getSalesList();
    } catch (error) {
      console.error(error);
    }
  }

  @action
  handleOpenConversation = (func, chatId) => {
    func(chatId);
    if (window.innerWidth <= 768) {
      this.combineHideModal();
    } else {
      this.changeShow();
    }
  }

  @action
  toggleCombineShow = () => {
    if (this.isShowBox) {
      this.combineHideModal();
      return;
    }
    this.combineShowModal();
  }

  @action
  combineShowModal = () => {
    this.showModal();
    setTimeout(() => {
      this.changeShow();
    }, 100);
  }

  @action
  combineHideModal = () => {
    this.hideModal();
    this.changeShow();
  }
}

const salesPopup = new SalesPopup();

export default salesPopup;
