/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { Px, commonStyles } from '../../posize';
import C06 from './C06';

export default function P006(props) {
  return (
    <div className={`p006 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="0px 428fr 0px"
        y="46fr minmax(0px, max-content) 46fr"
        className={css(styles.flex)}
      >
        <Px
          x="47.5fr 334fr 46.5fr"
          y="84fr minmax(0px, max-content) 0px"
          className={css(styles.slogan)}
          xsX="47.5fr 334fr 46.5fr"
          xsY="50fr minmax(0px, max-content) 20px"
        >
          {props.slogan}
        </Px>
        <div className={css(styles.carousel)}>
          {props.carousel}
          {props._renderPreBtn}
          {props._renderNextBtn}
        </div>
        <Px.div
          x="20fr 392fr 16fr"
          y="38px minmax(0px, 185fr) 0px"
          className={css(styles.flex)}
          xsX="20fr 392fr 16fr"
          xsY="20px minmax(0px, 185fr) 0px"
        >
          <div className={css(styles.flex1_row)}>
            <div className={css(styles.group1, styles.group1_layout)}>
              <div
                className={css(styles.labeleg_box, styles.labeleg_box_layout)}
              >
                <pre className={css(styles.labeleg)}>{props.labeleg}</pre>
                <div
                  style={{ '--color': props.label }}
                  className={css(styles.label, styles.label_layout)}
                />
              </div>
            </div>
          </div>
          <h3 className={css(styles.labelct_box, styles.labelct_box_layout)}>
            <pre className={css(styles.labelct)}>{props.labelct}</pre>
          </h3>
          <h1 className={css(styles.title, styles.title_layout)}>
            {props.title}
          </h1>
          <h5 className={css(styles.description, styles.description_layout)}>
            {props.description}
          </h5>
        </Px.div>

        <div className={css(styles.flex2, styles.flex2_layout)}>
          <div className={css(styles.flex2_spacer)} />
          <div className={css(styles.flex2_col)}>
            <div className={css(styles.cover_group, styles.cover_group_layout)}>
              <div
                className={css(
                  styles.paragraph_body_wrap,
                  styles.paragraph_body_wrap_layout
                )}
              >
                <C06
                  image={props.matIcon1}
                  small_paragraph_body={props.mat1}
                />
              </div>
            </div>
          </div>
          <div className={css(styles.flex2_spacer1)} />
          <div className={css(styles.flex2_col)}>
            <div className={css(styles.cover_group, styles.cover_group_layout)}>
              <div
                className={css(
                  styles.small_paragraph_body_wrap,
                  styles.small_paragraph_body_wrap_layout
                )}
              >
                <C06
                  image={props.matIcon2}
                  small_paragraph_body={props.mat2}
                />
              </div>
            </div>
          </div>
          <div className={css(styles.flex2_spacer2)} />
          <div className={css(styles.flex2_col)}>
            <div className={css(styles.cover_group, styles.cover_group_layout)}>
              <div
                className={css(
                  styles.small_paragraph_body_wrap,
                  styles.small_paragraph_body_wrap_layout1
                )}
              >
                <C06
                  image={props.matIcon3}
                  small_paragraph_body={props.mat3}
                />
              </div>
            </div>
          </div>
        </div>
      </Px.div>
    </div>
  );
}

P006.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'visible',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  slogan: {
    display: 'flex',
    justifyContent: 'center',
    font: '100 14px/2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    margin: 0,
    width: '100%',
    height: '100%',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  carousel: {
    position: 'relative',
    width: '95%',
    paddingTop: '75%'
  },
  flex1_row: {
    display: 'flex'
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: 0
  },
  label: {
    backgroundColor: 'var(--color)'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: -1,
    left: 0,
    width: '100%',
    zIndex: 0
  },
  labeleg: {
    position: 'relative',
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 12px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '0px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '10px',
      textAlign: 'left'
    },
    zIndex: 1
  },
  labeleg_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labeleg_box_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: 0
  },
  labelct: {
    overflow: 'visible',
    marginTop: 0,
    marginBottom: 0,
    font: '900 20px/1 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      fontSize: '17px',
      textAlign: 'left'
    }
  },
  labelct_box: {
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  labelct_box_layout: {
    position: 'relative',
    margin: '1px 0px 0px'
  },
  title: {
    font: '900 32px/1.375 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '26px',
      textAlign: 'left'
    }
  },
  title_layout: {
    position: 'relative',
    margin: '5px 0px 0px'
  },
  description: {
    font: '16px/1.875 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '12px',
      textAlign: 'left'
    }
  },
  description_layout: {
    position: 'relative',
    margin: '11px 30px 0px 0px'
  },
  flex2: {
    display: 'flex'
  },
  flex2_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: '15px 30px 0px 0'
  },
  flex2_spacer: {
    display: 'flex',
    flex: '0 1 7px'
  },
  flex2_col: {
    display: 'flex',
    flex: '0 1 135px'
  },
  cover_group_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  paragraph_body_wrap: {
    position: 'relative'
  },
  paragraph_body_wrap_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  flex2_spacer1: {
    display: 'flex',
    flex: '0 1 5px'
  },
  cover_group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  small_paragraph_body_wrap: {
    position: 'relative'
  },
  small_paragraph_body_wrap_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: '2.26px 0.45px'
  },
  flex2_spacer2: {
    display: 'flex',
    flex: '0 1 5px'
  },
  small_paragraph_body_wrap_layout1: {
    position: 'relative',
    flexGrow: 1,
    margin: '1.99px 0.1px'
  }
});

P006.propTypes = {
  slogan: PropTypes.string,
  carousel: PropTypes.element,
  _renderNextBtn: PropTypes.element,
  _renderPreBtn: PropTypes.element,
  label: PropTypes.string,
  labelct: PropTypes.string,
  labeleg: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  mat1: PropTypes.string,
  mat2: PropTypes.string,
  mat3: PropTypes.string,
  matIcon1: PropTypes.string,
  matIcon2: PropTypes.string,
  matIcon3: PropTypes.string
};

P006.defaultProps = {
  slogan: null,
  carousel: null,
  _renderNextBtn: null,
  _renderPreBtn: null,
  label: null,
  labelct: null,
  labeleg: null,
  title: null,
  description: null,
  mat1: null,
  mat2: null,
  mat3: null,
  matIcon1: null,
  matIcon2: null,
  matIcon3: null
};
