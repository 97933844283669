import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Pxcode from './Promo_005';
import styles from './styles.module.css';

@observer
class SectionC extends Component {
  constructor(props) {
    super(props);
    this.viewModel = props.viewModel;
  }

  componentDidMount() {
    window.addEventListener('resize', this.viewModel.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.viewModel.handleResize);
  }

  render() {
    const { viewModel } = this;
    return (
      <div
        className={styles.sectionContainer}
      >
        <Pxcode
          indexdesignerbg={viewModel.indexdesignerbg}
          indexdesigner1={viewModel.indexdesigner1}
          indexdesigner2={viewModel.indexdesigner2}
          labeleg={viewModel.labeleg}
          labelct={viewModel.labelct}
          label={viewModel.label}
          title={viewModel.title}
          description={viewModel.description}
          ratio={viewModel.ratio}
        />
      </div>
    );
  }
}

SectionC.propTypes = {
  viewModel: PropTypes.object
};

SectionC.defaultProps = {
  viewModel: null
};

export default SectionC;
