import React from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Input from 'src/components/Common/Input';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import { Button } from 'antd';
import styles from './styles.module.css';

export default function OtpForm({ seconds, onSubmit, loading, btnText }) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      { seconds === 0 ? (
        <div className={styles.row}>
          <h3 className={styles.form_title}>
            驗證碼已
            <span className={styles.seconds}>
              失效
            </span>
            ，請重新發送。
          </h3>
        </div>
      ) : (
        <div className={styles.row}>
          <h3 className={styles.form_title}>
            系統已發送認證碼，請在
            {' '}
            <span className={styles.seconds}>
              {seconds}
              秒
            </span>
            {' '}
            內完成驗證。
          </h3>
        </div>
      )}

      <div className={errors.otp ? styles.inputErrorContainer : styles.inputContainer}>
        <div className={clsx(styles.otp_content)}>
          <Input
            control={control}
            name="otp"
            rules={{
              validate: (value) => /\d{6}/.test(value)
            }}
            placeholder="輸入簡訊驗證碼"
            className={[
              styles.otp_input,
              errors.phone
            ]}
            maxLength={6}
          />
        </div>
        <Button htmlType="submit" className={styles.login_btn} loading={loading} disabled={seconds === 0}>
          {btnText}
        </Button>
      </div>
      <div className={styles.row}>
        <ErrorMessage error={errors.otp} label="驗證碼格式錯誤，請輸入六位數字" />
      </div>
    </form>
  );
}

OtpForm.propTypes = {
  seconds: PropTypes.number,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  btnText: PropTypes.string
};

OtpForm.defaultProps = {
  seconds: 0,
  onSubmit: () => {},
  loading: false,
  btnText: '驗證'
};
