import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CustomizeCarousel from '../../CustomizeCarousel';

import styles from './styles.module.css';

@observer
class SectionE extends React.Component {
  render() {
    const { viewModel } = this.props;
    const { carouselViewModel } = viewModel;
    return (
      <div className={styles.sectionContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <span className={styles.titleEg}>{viewModel.titleEg}</span>
            <span className={styles.titleCt}>{viewModel.titleCt}</span>
          </div>
          <div className={styles.carouselContainer}>
            <CustomizeCarousel
              viewModel={carouselViewModel}
              device="mobile"
            />
            <div
              className={styles.nextBtn}
              onClick={() => carouselViewModel.carousel.slideNext()}
            />
            <div
              className={styles.preBtn}
              onClick={() => carouselViewModel.carousel.slidePrev()}
            />
            <img
              className={styles.bg}
              src={viewModel.indexnewsbg}
              alt="bg"
            />
          </div>
        </div>
        <div className={styles.blueBg} />
      </div>
    );
  }
}

SectionE.propTypes = {
  viewModel: PropTypes.object
};

SectionE.defaultProps = {
  viewModel: null
};


export default SectionE;
