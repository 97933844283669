export default class Amount {
  constructor(params) {
    const { totalTransactionAmount } = params ?? {};

    this.totalTransactionAmount = totalTransactionAmount;
  }

  static fromRes(data) {
    return new Amount(data);
  }
}
