export default class ISection {
  constructor(props) {
    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.deserialize = this.deserialize.bind(this);
    // id, name
  }
  onEnter() {
    throw new Error("Don't forget to overwrite onEnter!");
  }
  onLeave() {
    throw new Error("Don't forget to overwrite onLeave!");
  }

  deserialize(param) {
    throw new Error("Don't forget to overwrite deserialize!");
  }
}
