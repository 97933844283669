export default class Model {
  constructor(props) {
    this.deserialize(props);
  }

  deserialize = (props) => {
    const {
      type,
      title,
      subtitle,
      children
    } = props;

    this.type = type;
    this.title = title;
    this.subtitle = subtitle;
    this.children = children;
  }
}
