import { observable, action, makeObservable, computed } from 'mobx';

import { SectionType } from 'src/constants';
import ISection from './ISection';

export default class SectionCViewModel extends ISection {
  @observable id;
  @observable indexdesigner1;
  @observable indexdesigner2;
  @observable indexdesignerbg;
  @observable labeleg;
  @observable label;
  @observable labelct;
  @observable title;
  @observable description;
  @observable height = window.innerHeight;
  @observable width = window.innerWidth;


  get type() {
    return SectionType.C;
  }

  constructor(params) {
    super(params);

    makeObservable(this);
    this.deserialize(params);
  }

  @action
  handleResize = () => {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  @action
  deserialize = (params) => {
    const {
      id,
      indexdesigner1,
      indexdesigner2,
      indexdesignerbg,
      labeleg,
      label,
      labelct,
      title,
      description
    } = params ?? {};

    this.id = id;
    this.indexdesigner1 = indexdesigner1;
    this.indexdesigner2 = indexdesigner2;
    this.indexdesignerbg = indexdesignerbg;
    this.labeleg = labeleg;
    this.label = label;
    this.labelct = labelct;
    this.title = title;
    this.description = description;
  };

  onEnter = () => {};

  onLeave = () => {};

  @computed
  get ratio() {
    return this.width / this.height > 1;
  }
}
