import React from 'react';

export default function Terms() {
  return (
    <div>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第一條 基本條款</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本會員服務條款所稱之「會員」為依照本網站(網址：www.maauu.com)(下稱本網站)所定之程序完成加入會員並通過細細生活網路科技股份有限公司(下稱本公司)認證者。
二. 當會員完成會員註冊手續或開始使用本網站服務時，即表示已閱讀、瞭解並同意接受本會員條款之所有內容，並接受本服務相關衍生的服務項目及內容。
三. 本公司保有審核加入會員資格之權利，就已加入會員者，本公司亦保有解除其會員資格之權利。
四. 本公司有權於任何時間修改或變更本會員條款之內容，修改後的會員條款內容將公佈於網站上，會員應隨時注意該等修改或變更，本公司不於修改或變更後個別通知會員。會員於條款做任何修改或變動後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的會員條款修訂或更新方式，或不接受本會員條款的其他任一約定，會員應立即停止使用本服務。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第二條 服務申請及資料提供</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本網站僅係本公司為建商或代銷公司(下稱建商代銷端)所指定建案設置之網頁，會員瞭解並同意遵守以下之使用約定：
(一) 倘會員透過本網站點選客服服務，將與本公司安排之客服人員進行溝通或視訊通話。
(二) 倘會員透過本網站自行聯繫建商代銷端，或透過本公司客服人員，將與建商代銷端安排之人員進行會面行為，與本公司無涉。
(三) 倘會員於本網站點選「付款」功能，會員得以信用卡或網路轉帳功能，直接付款與建商代銷端，以取得該建案該戶下訂之權利，與本公司無涉。
(四) 倘會員下訂建商代銷端物件時，選定本公司為介紹人，所獲得之獎勵內容、時程，與方式，將依本公司或本公司合作外部特派員，所告知會員之內容、時程，與方式為準。本公司有權於任何時間修改或變更，不於修改或變更後個別通知會員。
(五) 上述第(4)點之獎勵，本公司將透過會員所提供之渠道發放，不負有查核該渠道正確之責任，亦不負有追蹤或確認獎勵收取後續之責任。會員負有提供該渠道之正確性與合法性責任。
二. 倘會員有違反前項約定之情事，會員應負賠償之責。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第三條 使用及異動</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本網站僅係本公司為建商或代銷公司(下稱建商代銷端)所指定建案設置之網頁，會員瞭解並同意遵守以下之使用約定：
(一) 倘會員透過本網站點選客服服務，將與本公司安排之客服人員進行溝通或視訊通話。
(二) 倘會員透過本網站自行聯繫建商代銷端，或透過本公司客服人員，將與建商代銷端安排之人員進行會面行為，與本公司無涉。
(三) 倘會員於本網站點選「付款」功能，會員得以信用卡或網路轉帳功能，直接付款與建商代銷端，以取得該建案該戶下訂之權利，與本公司無涉。
(四) 倘會員下訂建商代銷端物件時，選定本公司為介紹人，所獲得之獎勵內容、時程，與方式，將依本公司或本公司合作外部特派員，所告知會員之內容、時程，與方式為準。本公司有權於任何時間修改或變更，不於修改或變更後個別通知會員。
(五) 上述第(4)點之獎勵，本公司將透過會員所提供之渠道發放，不負有查核該渠道正確之責任，亦不負有追蹤或確認獎勵收取後續之責任。會員負有提供該渠道之正確性與合法性責任。
二. 倘會員有違反前項約定之情事，會員應負賠償之責。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第四條 會員帳號管理</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 會員於本網站填列手機號碼後，本網站將以簡訊發送認證碼至會員填列之手機號碼以供認證。完成認證後，會員始得於本網站填寫資料並完成註冊程序。
二. 會員完成註冊程序後，會員帳號即為經認證之手機號碼，會員往後得於本網站輸入會員帳號並經簡訊認證即可登入本網站。
三. 會員之帳號、密碼及相關權益，均僅供會員使用及享有，不得轉借、轉讓他人或與他人合用。
四. 會員應維持帳號之機密安全。會員對自身之帳號因管理不善所生之結果應自行負責。
五. 任何依照規定方法輸入會員帳號及認證碼而與登入資料一致時，無論是否由會員輸入，均視為會員所使用，利用該帳號所進行之一切行為，會員應負完全責任。若因該帳號為非會員或非經會員授權之員工使用而造成本公司之損害，會員應負賠償之責。
六. 倘會員之帳號遭他人盜用或不當使用，除證明係因可歸責於本公司之事由所致者，本公司對於衍生之損害不負任何責任。
七. 本公司知悉會員之帳號確係遭他人冒用或盜用時，得立即暫停該帳號之使用，並暫停提供本服務。若係可歸責於會員而遭他人冒用或盜用，因而造成本公司受有損害者，本公司可請求會員賠償所受損害。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第五條 服務之維持與中斷</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本公司依一般合理之技術及方式，維持本網站及本服務之正常運作。
二. 於以下各項情況，本公司有權中止、中斷提供本服務及建案網頁之全部或一部，且對會員之任何損害均不負賠償或補償之責任：
(一) 為維護良好服務品質及遇有系統故障，本服務相關軟硬體設備進行檢修或障礙排除工作，包括但不限於搬遷、更換、升級、保養或維修時。
(二) 發生突發性之電子通信設備故障時。
(三) 本服務及建案網頁所使用之電子通信服務因任何原因被停止，無法提供服務時。
(四) 因天災等不可抗力因素或其他不可歸責於本公司致使無法提供本服務時。
(五) 其他不可歸責於本公司之事由所致之本服務停止或中斷。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第六條 服務之終止</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 倘本公司因業務縮減、技術上限制或其他因素致無法提供本服務，得於預定終止本服務之日前15日於本網站或個別通知會員，會員不得異議或要求任何補償或賠償。
二. 倘發生以下各項情況者，本公司得不經事前通知，即終止本服務：
(一) 有相當事證足認會員利用本服務及建案網頁為詐欺等犯罪行為或不法行為者。
(二) 會員提供虛偽不實之資料或證明文件，經查證屬實者。
(三) 違反本會員條款且情節重大者。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第七條 免責聲明</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本服務僅係為建商代銷端指定之建案內容，架設網頁、廣告投放，或真人客服，以供會員瀏覽，不涉入會員與建商代銷端間之任何實際銷售或交易行為。
二. 本網站無涉於任何房屋預約、說明、仲介，或合約簽訂之交易行為，僅使會員取得與建商代銷端進行磋商或洽談之權利，無涉任何買賣、給付或付款。實際交易相關行為乃會員與建商代銷端，即買賣雙方間為履行交易所生的行為，概與本公司無涉。
三. 本網站依合作之第三方支付平台使用規則，提供會員與建商代銷端進行信用卡或網路轉帳下訂。倘因會員使用之信用卡發卡銀行之疏失而有任何爭議，本公司概不負責。
四. 會員與建商代銷端間交易所衍生之糾紛，應由會員與建商代銷端自行處理，本公司概不負責。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第八條 資料之蒐集、處理及利用</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 會員為使用本服務所提供之資料，除該建商代銷端有權取得之外，本公司均將妥善保管並予以保密，並保護此等資料免於滅失或任何未經授權之存取。非經會員同意，本公司不會洩漏或交付予他人。本條保密義務，不因本合約之終止而失效。
二. 會員使用本服務時，即同意本公司依據「隱私權聲明」進行會員個人資料的蒐集、處理與利用。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第九條 智慧財產權約款</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本公司之商標、服務標誌、商業名稱、徽標、網域名稱以及本服務品牌的其他特色，均為本公司之獨有財產。本會員條款並未授予會員使用上開本公司財產之任何權利。
二. 本服務與建案網頁所使用之產品、軟體、圖片、程式、網站上所有內容，包括但不限於著作、文字、圖片、照片、音訊、影片、圖表、色彩組合、網站架構、版面設計等，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。
三. 若會員欲引用或轉載前述軟體、程式或網站內容，必須依法取得本公司或其他權利人之事前書面同意。如有違反，會員應對本公司負損害賠償責任。
四. 會員同意且擔保其在使用本服務時，不為侵害他人智慧財產權之行為。
五. 若會員違反上述之約定而有涉及侵權之情事，本公司可暫停全部或部分之服務，或逕自以取消會員帳號之方式處理，且若因而造成損害本公司之損害權益時，本公司並可請求損害賠償。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第十條 準據法與管轄權約定</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本會員條款之任何條款之全部或一部無效時，不影響其他約定之效力。
二. 本會員條款之解釋與適用，及本會員條款所生之一切爭議或糾紛，以中華民國法律為準據法，並以臺灣臺北地方法院為第一審管轄法院。

`
        }
      </p>

    </div>
  );
}
