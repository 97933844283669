import { makeObservable, observable, action, computed } from 'mobx';

import { SectionType } from 'src/constants';
import CarouselViewModel from 'src/viewModels/Carousel';
import CustomizeModalViewModel from 'src/viewModels/CustomizeModal';
import ISection from './ISection';

export default class SectionDViewModel extends ISection {
  @observable id;
  @observable name;
  @observable public1;
  @observable public2;
  @observable imageList = [];
  @observable detailImage = { id: 0, name: '', value: '' };
  @observable popup = new CustomizeModalViewModel();
  @observable icon1;
  @observable icon2;
  @observable icon3;
  @observable info1;
  @observable info2;
  @observable info3;

  get type() {
    return SectionType.D;
  }

  constructor(props) {
    super(props);
    makeObservable(this);

    this.deserialize(props);
    this.carouselViewModel = new CarouselViewModel(this.imageList);
  }

  @action
  showDetail = (id) => {
    const targetImage = this.imageList.find((item) => item.id === id);
    this.detailImage = targetImage;
    if (!this.popup.isShow) {
      this.popup.showModal();
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }

  @action
  handleKeyDown = (e) => {
    if (e.code === 'ArrowRight') {
      e.preventDefault();
      this.nextImg();
    } else if (e.code === 'ArrowLeft') {
      this.previousImg();
    } else if (e.code === 'Escape') {
      this.combineClose();
    }
  }

  @action
  previousImg = () => {
    const nowImgId = this.detailImage.id;
    const previousId = this.detailImage.id === 1 ? this.imageList.length : nowImgId - 1;
    const preImg = this.imageList.find((img) => img.id === previousId);
    this.detailImage = preImg;
  }

  @action
  nextImg = () => {
    const nowImgId = this.detailImage.id;
    const nextId = this.detailImage.id === this.imageList.length ? 1 : nowImgId + 1;
    const nextImg = this.imageList.find((img) => img.id === nextId);
    this.detailImage = nextImg;
  }

  @action
  combineClose = () => {
    this.popup.hideModal();
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  onEnter = () => { };

  onLeave = () => {
    this.combineClose();
  };

  @action
  deserialize = (params) => {
    const {
      id,
      name,
      public1,
      public2,
      indexPub1,
      indexPub2,
      indexPub3,
      indexPub4,
      indexPub5,
      indexPub6,
      icon1,
      icon2,
      icon3,
      info1,
      info2,
      info3

    } = params ?? {};

    this.id = id;
    this.name = name;
    this.public1 = public1;
    this.public2 = public2;
    this.icon1 = icon1;
    this.icon2 = icon2;
    this.icon3 = icon3;
    this.info1 = info1;
    this.info2 = info2;
    this.info3 = info3;
    this.imageList.push({
      id: 1,
      type: 'image',
      name: 'indexPub1',
      value: indexPub1
    });
    this.imageList.push({
      id: 2,
      type: 'image',
      name: 'indexPub2',
      value: indexPub2
    });
    this.imageList.push({
      id: 3,
      type: 'image',
      name: 'indexPub3',
      value: indexPub3
    });
    this.imageList.push({
      id: 4,
      type: 'image',
      name: 'indexPub4',
      value: indexPub4
    });
    this.imageList.push({
      id: 5,
      type: 'image',
      name: 'indexPub5',
      value: indexPub5
    });
    this.imageList.push({
      id: 6,
      type: 'image',
      name: 'indexPub6',
      value: indexPub6
    });
    this.detailImage = this.imageList[0];
  };
}
