/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { Px, commonStyles } from '../../posize';

export default function P004(props) {
  return (
    <div
      className={`p004 ${css(styles.content_box, styles.content_box_layout)}`}
    >
      <div className={css(styles.content_box_row)}>
        <div className={css(styles.flex, styles.flex_layout)}>
          <div className={css(styles.flex_col)}>
            <div
              data-background={props.indexDesign2}
              className={`${css(styles.img, styles.img_layout)} swiper-lazy`}
            />
          </div>
          <div className={css(styles.flex_col)}>
            <div
              data-background={props.indexDesign1}
              className={`${css(styles.img, styles.img_layout)} swiper-lazy`}
            />
          </div>
        </div>
      </div>
      <div className={css(styles.content_box_row1)}>
        <div className={css(styles.flex, styles.flex_layout1)}>
          <div className={css(styles.flex1_col)}>
            <div
              data-background={props.indexDesign4}
              className={`${css(styles.img, styles.img_layout)} swiper-lazy`}
            />
          </div>
          <div className={css(styles.flex1_col)}>
            <div
              data-background={props.indexDesign3}
              className={`${css(styles.img, styles.img_layout)} swiper-lazy`}
            />
          </div>
        </div>
      </div>
      <div className={css(styles.content_box_row2)}>
        <div
          data-background={props.indexDesign5}
          className={`${css(styles.cover_group, styles.cover_group_layout)} swiper-lazy`}
        >
          <Px.div
            x="305fr minmax(0px, max-content) 23px"
            y="361.9fr minmax(0px, max-content) 21px"
            trackStyle={{ overflow: 'auto' }}
            areaStyle={{ overflow: 'hidden' }}
            className={css(styles.cover_group1)}
          >
            {/* <div
              className={css(
                styles.small_text_body,
                styles.small_text_body_layout
              )}
            >
              設計風格示意圖
            </div> */}
          </Px.div>
        </div>
      </div>
    </div>
  );
}

P004.inStorybook = true;

const styles = StyleSheet.create({
  content_box: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(255,255,255)'
  },
  content_box_layout: {
    position: 'absolute',
    height: '100%',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  content_box_row: {
    display: 'flex',
    flex: '1 1 261.44px'
  },
  flex: {
    display: 'flex'
  },
  flex_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  flex_col: {
    display: 'flex',
    flex: '1 1 214px'
  },
  img: {
    background: 'center center / cover no-repeat'
  },
  img_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0
  },
  content_box_row1: {
    display: 'flex',
    flex: '1 1 261.13px'
  },
  flex_layout1: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1
  },
  flex1_col: {
    display: 'flex',
    flex: '1 1 214px'
  },
  content_box_row2: {
    display: 'flex',
    flex: '1 1 403.43px'
  },
  cover_group: {
    display: 'flex',
    background: 'center center / cover no-repeat'
  },
  cover_group_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1
  },
  cover_group1: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)',
    borderWidth: 0,
    borderRadius: '10px 10px 10px 10px',
    width: '100%',
    height: '100%',
    opacity: 0.8
  },
  small_text_body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: '11px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    margin: 0
  },
  small_text_body_layout: {
    position: 'relative',
    minHeight: 20,
    flexGrow: 1,
    padding: '3px 8px 2px 9px'
  }
});

P004.propTypes = {
  indexDesign2: PropTypes.string,
  indexDesign3: PropTypes.string,
  indexDesign4: PropTypes.string,
  indexDesign5: PropTypes.string,
  indexDesign1: PropTypes.string
};

P004.defaultProps = {
  indexDesign2: null,
  indexDesign3: null,
  indexDesign4: null,
  indexDesign5: null,
  indexDesign1: null
};
