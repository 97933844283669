import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import PxCode from './P012';

import styles from './styles.module.css';

@observer
class SectionG extends React.Component {
  render() {
    const { viewModel, swiper } = this.props;
    return (
      <div>
        <PxCode
          infoCase={viewModel.infoCase}
          info={viewModel.info}
          infoeng={viewModel.infoeng}
          icon1={viewModel.icon1}
          info1={viewModel.info1}
          icon2={viewModel.icon2}
          info2={viewModel.info2}
          icon3={viewModel.icon3}
          info3={viewModel.info3}
          infoSubTit1={viewModel.infoSubTit1}
          infoSubTit2={viewModel.infoSubTit2}
          map={viewModel.map}
          // shoppingbutton={viewModel.shoppingbutton}
          swiper={swiper}
        />
      </div>
    );
  }
}

SectionG.propTypes = {
  viewModel: PropTypes.object,
  swiper: PropTypes.object
};

SectionG.defaultProps = {
  viewModel: null,
  swiper: null
};

export default SectionG;
