import { action, computed, makeObservable, runInAction } from 'mobx';
import Toastify from 'toastify-js';

import 'toastify-js/src/toastify.css';

export default class ShareBtnViewModel {
  constructor() {
    makeObservable(this);
  }
  @action
  lineShare = (isSharedHref) => {
    const title = '我發現一個很好的建案，一起來看看吧：';
    const encodeUrl = encodeURIComponent(isSharedHref ? window.location.href : window.location.origin);


    window.open(`https://social-plugins.line.me/lineit/share?url=${encodeUrl}&text=${title}`, '', 'height=700, width=500');
  }

  @action
  fbShare = (isSharedHref) => {
    const current = isSharedHref ? window.location.href : window.location.origin;
    const title = '我發現一個很好的建案，一起來看看吧：';

    window.open(`https://www.facebook.com/sharer.php?u=${current}&quote=${title}`, '', 'height=700, width=500');
  }

  @action
  copyLink = async (isSharedHref) => {
    try {
      const current = isSharedHref ? window.location.href : window.location.origin;
      await navigator.clipboard.writeText(current);
      runInAction(() => {
        Toastify({
          text: '複製成功！',
          duration: 1500,
          gravity: 'bottom',
          position: 'center',
          offset: {
            x: 0,
            y: 30
          },
          style: {
            font: '600 16px "PingFang TC"',
            background: '#333',
            color: '#fff',
            letterSpacing: '1.2px',
            borderRadius: '5px'
          }
        }).showToast();
      });
    } catch (error) {
      console.log(error);
    }
  }
  @computed
  get lineShareHref() {
    const current = `https://${window.location.host}`;
    const title = '我發現一個很好的建案，一起來看看吧：';

    return `https://line.me/R/msg/text/?${title + current}`;
  }
}
