import { observable, action, makeObservable } from 'mobx';
import React from 'react';

import CustomizeModalViewModel from 'src/viewModels/CustomizeModal';
import { SectionType } from 'src/constants';
import ISection from './ISection';

// section A: Main Page with case title.
export default class SectoinAViewModel extends ISection {
  @observable id;
  @observable videoRef = React.createRef();
  @observable popup = new CustomizeModalViewModel();
  @observable background;
  @observable iframe;
  @observable subtitle1;
  @observable subtitle2;
  @observable description1;
  @observable description2;
  @observable description3;
  @observable description4;
  @observable description1Unit;
  @observable description2Unit;
  @observable description3Unit;
  @observable description4Unit;
  @observable intro1;
  @observable intro2;
  @observable intro3;
  @observable intro4;
  @observable name;
  // @observable shoppingbutton;
  @observable casetitle;
  @observable videoIcon;
  @observable videoName;

  get type() {
    return SectionType.A;
  }

  constructor(props) {
    super(props);

    makeObservable(this);
    this.deserialize(props);
  }

  @action
  openVideo = () => {
    this.videoRef.current.onEnter();
    this.popup.showModal();
  }

  @action
  closeVideo = () => {
    this.videoRef.current.onLeave();
    this.popup.hideModal();
  }

  onEnter = () => { };

  @action
  onLeave = () => {
    this.videoRef.current?.onLeave();
  };

  @action
  deserialize = (params) => {
    const {
      id,
      background,
      iframe,
      subtitle1,
      subtitle2,
      description1,
      description2,
      description3,
      description4,
      intro1,
      intro2,
      intro3,
      intro4,
      name,
      // shoppingbutton,
      casetitle,
      videoIcon,
      videoName
    } = params ?? {};

    this.id = id;
    this.background = background ?? '';
    this.iframe = iframe ?? '';
    this.subtitle1 = subtitle1 ?? '';
    this.subtitle2 = subtitle2 ?? '';
    this.name = name ?? '';
    // this.shoppingbutton = shoppingbutton ?? '';
    this.casetitle = casetitle ?? '';
    this.intro1 = intro1;
    this.intro2 = intro2;
    this.intro3 = intro3;
    this.intro4 = intro4;
    this.videoIcon = videoIcon;
    this.videoName = videoName;
    this.description1 = description1?.slice(0, -1) ?? '';
    this.description2 = description2?.slice(0, -1) ?? '';
    this.description3 = description3?.slice(0, -1) ?? '';
    this.description4 = description4?.slice(0, -1) ?? '';
    this.description1Unit = description1?.slice(-1) ?? '';
    this.description2Unit = description2?.slice(-1) ?? '';
    this.description3Unit = description3?.slice(-1) ?? '';
    this.description4Unit = description4?.slice(-1) ?? '';
  };
}
