import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';

import OrdererInfo from '../models/OrdererInfo';


const Orderer = (props) => {
  const orderer = OrdererInfo.fromJSON(props.orderer);

  const fields = orderer.toFields();

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Text style={styles.tag}>ORDERER</Text>
      </View>

      <View style={styles.right}>
        {
          fields.map((f) => (
            <View
              key={f.key}
              style={styles.field}
            >
              <Text style={styles.fieldTxt}>{ `${f.label}：${f.value}` }</Text>
            </View>
          ))
        }
      </View>
    </View>
  );
};

Orderer.propTypes = {
  orderer: PropTypes.string
};

Orderer.defaultProps = {
  orderer: null
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '25px 40px 50px 40px'
    // border: '1px solid red'
  },
  left: {

  },
  tag: {
    fontFamily: 'custom',
    fontWeight: 700,
    fontSize: 14,
    color: '#BDBDBD'
  },
  right: {
    margin: '8px 40px 0 35px'
    // border: '1px solid red'
  },
  field: {

  },
  fieldTxt: {
    fontFamily: 'custom',
    fontSize: 12,
    fontWeight: 400,
    color: '#282828',
    lineHeight: 2
  }
});

export default Orderer;
