import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import clsx from 'clsx';

import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import PageViewModel from 'src/viewModels/Advertisement/PageViewModel';
import LoadingGif from 'src/assets/loading.gif';

import CustomizeModal from 'src/components/CustomizeModal';
import MobileIndex from './components/MobileSections';
import WebIndex from './components/WebSections';
import styles from './styles.module.css';

@observer
class Advertisement extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new PageViewModel(this.props.handleChangeSection);
  }

  componentDidMount() {
    this.viewModel.didMount(this.props);
  }

  render() {

    return (
      <div className="page">
        <MediaQuery minWidth={769}>
          <WebIndex
            viewModel={this.viewModel}
          />
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <MobileIndex
            viewModel={this.viewModel}
          />
        </MediaQuery>

        <CustomizeModal
          shouldIgnorePuppeteer
          bgColor="#fff"
          className={clsx('loadingModal', styles.modal, this.viewModel.isLoadingFinished && styles.hide)}
        >
          <div className={styles.loading_container}>
            <img src={LoadingGif} width={120} height={120} alt="loading" />
            <div className={styles.loading_title}>PLEASE WAIT...</div>
          </div>
        </CustomizeModal>
      </div>
    );
  }
}

Advertisement.propTypes = {
  handleChangeSection: PropTypes.func
};

Advertisement.defaultProps = {
  handleChangeSection: null
};

export default withAppStateContext(Advertisement);
