/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import UserService from 'src/services/user';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import Form from './Form';
import styles from './styles.module.css';

export default function UserCard({ profile }) {
  const history = useHistory();
  const [formDisabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { actions } = useAppStateContext();

  useEffect(() => {
    if (!profile) {
      setLoading(true);

    } else {
      setLoading(false);
    }
  }, [profile]);

  const onEdit = () => {
    setDisabled(false);
  };

  const onSave = async (data) => {
    const bodyFormData = new FormData();

    if (data.firstName) {
      bodyFormData.append('firstName', data.firstName);
    }

    if (data.lastName) {
      bodyFormData.append('lastName', data.lastName);
    }

    if (data.email) {
      bodyFormData.append('email', data.email);
    }

    if (data.birthday) {
      bodyFormData.append('birthday', data.birthday);
    }

    if (data.postalCode) {
      bodyFormData.append('postalCode', data.postalCode);
    }

    if (data.address) {
      bodyFormData.append('address', data.address);
    }

    if (data.city) {
      bodyFormData.append('city', data.city);
    }


    if (data.region) {
      bodyFormData.append('region', data.region);
    }

    const user = await UserService.updateProfile(profile?.id, bodyFormData);
    actions.updateProfile(user);
    setDisabled(true);
  };

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <div className={styles.layout}>
        <div className={styles.content}>


          <div className={styles.form_layout}>
            {
              profile ? (
                <Form values={profile} onSubmit={onSave} disabled={false} />
              ) : null
            }
          </div>
        </div>
      </div>
    </Spin>
  );
}


UserCard.defaultProps = {
  profile: null
};

UserCard.propTypes = {
  profile: PropTypes.object
};
