export default class Sale {
  constructor(params) {
    const {
      id,
      telephone,
      group,
      lastName,
      firstName,
      isBlock,
      isVerified,
      email,
      avatar,
      gender,
      status
    } = params;

    this.id = id;
    this.telephone = telephone;
    this.group = group;
    this.lastName = lastName;
    this.firstName = firstName;
    this.isBlock = isBlock;
    this.isVerified = isVerified;
    this.email = email;
    this.avatar = avatar;
    this.gender = gender;
    this.status = status;
  }

  static fromRes(data) {
    return new Sale(data);
  }
}
