import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import CustomizeCarousel from '../../CustomizeCarousel';
import styles from './styles.module.css';


@observer
class SectionE extends React.Component {
  render() {
    const vm = this.props.viewModel;
    const carouselVm = vm.carouselViewModel;
    return (
      <div className={styles.sectionContainer}>
        <div className={styles.sectionName}>
          <span className={styles.egName}>{vm.titleEg}</span>
          <span className={styles.ctName}>{vm.titleCt}</span>
        </div>
        <div className={styles.gridContainer}>
          <div className={styles.carouselContainer}>
            <CustomizeCarousel
              viewModel={carouselVm}
            />
            <div className={styles.cover}>
              <div
                className={styles.nextBtn}
                onClick={() => carouselVm.carousel.slideNext()}
              />
              <div
                className={styles.preBtn}
                onClick={() => carouselVm.carousel.slidePrev()}
              />
            </div>
            <div className={styles.newsBg}>
              <img src={vm.indexnewsbg} alt="background" />
            </div>
          </div>
        </div>
        <div className={styles.oval} />
        <div className={styles.blueBar} />
      </div>
    );
  }
}

SectionE.propTypes = {
  viewModel: PropTypes.object
};

SectionE.defaultProps = {
  viewModel: null
};

export default SectionE;
