import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Theme } from 'src/components/Modal/themes';
import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import withProfile from 'src/components/withProfile';
import UserService from 'src/services/user';
import ErrorMessageService from 'src/services/errorMessage';


@observer
class NewebPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      host: '',
      merchantId: '',
      tradeInfo: '',
      tradeSha: '',
      version: ''
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    await this.createForm(this.props);
  }

  createForm = async (props) => {
    const { history, match, location, appContext } = props ?? {};

    try {
      const price = new URLSearchParams(location.search).get('price');
      const spacesPrice = new URLSearchParams(location.search).get('spacesPrice');

      const data = await UserService.createNewebpayForm(match.params.uid, match.params.pid, price, spacesPrice);

      this.setState({
        host: data.host,
        merchantId: data.merchantId,
        tradeInfo: data.tradeInfo,
        tradeSha: data.tradeSha,
        version: data.version
      });

      this.formRef.current.submit();
    } catch (err) {

      appContext.actions.showModal({
        theme: Theme.warning,
        title: 'INFO',
        content: ErrorMessageService.createNewebpayForm(err),
        okText: '確定',
        onOk: () => {
          // TODO 待討論
          history.push('/pickout');
        },
        onCancel: () => {
          // TODO 待討論
          history.push('/pickout');
        }
      });
    }
  }


  render() {
    return (
      <div style={{ display: 'none' }}>
        <form method="post" action={`${this.state.host}/MPG/mpg_gateway`} ref={this.formRef}>
          <input name="MerchantID" value={this.state.merchantId} readOnly />
          <input name="TradeInfo" value={this.state.tradeInfo} readOnly />
          <input name="TradeSha" value={this.state.tradeSha} readOnly />
          <input name="Version" value={this.state.version} readOnly />
        </form>
      </div>
    );

  }
}

export default withProfile(withAppStateContext(withRouter(NewebPay)), false);
