import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

function Iframe(props, ref) {
  const { value, isGrayScale } = props;
  const iframeRef = useRef();
  const isGoogleMap = value.includes('www.google.com/maps');

  useImperativeHandle(
    ref,
    () => ({
      onEnter() {
        // https://stackoverflow.com/questions/13619074/play-iframe-video-on-click-a-link-javascript
      },
      onLeave() {

      }
    }),
    []
  );

  return (
    <iframe
      title="title"
      src={value}
      className={clsx(styles.layout, (isGoogleMap && isGrayScale) && styles.grayscale)}
      ref={iframeRef}
    />
  );
}

export default forwardRef(Iframe);
