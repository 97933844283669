import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import Model from './Model';

class PopupPic extends React.Component {
  constructor(props) {
    super(props);
    this.model = new Model(props.viewModel);
  }

  render() {
    return (
      <div className={styles.popupContainer}>
        <img className={styles.popImg} style={{ objectFit: this.model.imgSize }} src={this.model.image} alt="building" />
      </div>
    );
  }
}

PopupPic.propTypes = {
  viewModel: PropTypes.object
};

PopupPic.defaultProps = {
  viewModel: null
};

export default PopupPic;
