import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  View,
  StyleSheet
} from '@react-pdf/renderer';

import PlanJson from 'src/json/plan.json';

const Banner = (props) => {
  const src = PlanJson?.pic?.find((p) => `${p.type}`.toLowerCase() === 'image')?.value;

  return (
    <View style={styles.banner}>
      <Image
        style={styles.img}
        source={src}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  banner: {
    display: 'flex',
    // border: '1px solid red',
    margin: '10px 40px 10px 40px'
  },
  img: {

  }
});


export default Banner;
