import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './style.module.css';

const Select = React.forwardRef((props, ref) => {
  const { options, className, ...others } = props;
  return (
    <select className={clsx(styles.select, className)} {...others} ref={ref}>
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
});

Select.defaultProps = {
  options: [],
  className: ''
};

Select.propTypes = {
  options: PropTypes.array,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
};

export default Select;
