import API from 'src/apis';
import Building from 'src/models/responses/Building';
import Door from 'src/models/responses/Door';
import MonthlyPayment from 'src/models/responses/MonthlyPayment';
import Amount from 'src/models/responses/Amount';
import Plan from 'src/models/responses/Plan';

import AuthService from './auth';

export default class Plans {
  static async getBuildings() {
    const { data } = await API.plans.status(AuthService.getToken());
    const buildings = data.status.buildings.map((building) =>
      Building.fromRes(building));
    return buildings;
  }

  static async getTotalAmount() {
    const { data } = await API.plans.statistics(AuthService.getToken());
    return Amount.fromRes(data.statistics);
  }

  static async getPlanInfo() {
    const { data } = await API.plans.info();
    return Plan.fromRes(data.plan);
  }

  static async getDoorSlots(did) {
    const { data } = await API.plans.doorSlots(did);
    return Door.fromRes(data.door);
  }

  static async getRefer() {
    const { data } = await API.plans.getRefer();
    return data;
  }

}
