import { observable, action, makeObservable } from 'mobx';

import { SectionType } from 'src/constants';
import ISection from './ISection';

export default class SectionFViewModel extends ISection {
  @observable id;
  @observable indexDesign1 = '';
  @observable indexDesign2 = '';
  @observable indexDesign3 = '';
  @observable indexDesign4 = '';
  @observable indexDesign5 = '';

  get type() {
    return SectionType.F;
  }

  constructor(props) {
    super(props);
    // makeObservable(this);
    this.deserialize(props);
  }

  onEnter = () => { };

  onLeave = () => { };

  @action
  deserialize = (params) => {
    const {
      id,
      indexDesign1,
      indexDesign2,
      indexDesign3,
      indexDesign4,
      indexDesign5
    } = params;

    this.id = id;
    this.indexDesign1 = indexDesign1;
    this.indexDesign2 = indexDesign2;
    this.indexDesign3 = indexDesign3;
    this.indexDesign4 = indexDesign4;
    this.indexDesign5 = indexDesign5;
  }
}
