import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import cn from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';


@observer
class SectionH extends React.Component {
  render() {
    const { viewModel } = this.props;

    return (
      <div className={cn('imac', styles.block, styles.block_layout)}>
        <div
          className={cn(styles.picture, styles.picture_layout, 'swiper-lazy')}
        >
          <LazyLoadImage
            alt=""
            height="100%"
            width="100%"
            src={viewModel.picture}
            wrapperClassName={cn('loading_placeHolder', 'swiper-lazy')}
          />

        </div>
        <px-posize
          track-style='{"flexGrow":1}'
          x="0px 580fr 0px"
          y="1fr minmax(0px, max-content) 1fr"
          md-x="140px 580fr 140px"
          md-y="1fr minmax(0px, max-content) 1fr"
          sm-x="25px 580fr 25px"
          sm-y="1fr minmax(0px, max-content) 1fr"
          xs-x="25px 580fr 25px"
          xs-y="1fr minmax(0px, max-content) 1fr"
        >
          <div className={cn(styles.block1)}>
            <div className={cn(
              styles.cover_block,
              styles.cover_block_layout,
              viewModel.position === 'center' && styles.cover_block_center,
              viewModel.position === 'left' && styles.cover_block_left,
              viewModel.position === 'right' && styles.cover_block_right
            )}
            >
              <div className={cn(styles.flex, styles.flex_layout)}>
                <div className={cn(styles.flex_item)}>
                  <div className={cn(styles.group, styles.group_layout)}>
                    <div
                      style={{ backgroundColor: viewModel.color }}
                      className={cn(styles.box2, styles.box2_layout)}
                    />
                    <div
                      className={cn(
                        styles.subtitle2_box,
                        styles.subtitle2_box_layout
                      )}
                    >
                      <div className={cn(styles.subtitle2)}>
                        {viewModel.subtitle2}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    styles.subtitle1_box,
                    styles.subtitle1_box_layout
                  )}
                >
                  <div className={cn(styles.subtitle1)}>
                    {viewModel.subtitle1}
                  </div>
                </div>
                <div className={cn(styles.flex_spacer)} />
                <h1 className={cn(styles.title, styles.title_layout)}>
                  {viewModel.title}
                </h1>
                <h5 className={cn(styles.content, styles.content_layout)}>
                  {viewModel.content}
                </h5>
              </div>
            </div>
          </div>
        </px-posize>

        <div className={styles.diagram_box}>情境示意圖</div>
      </div>
    );
  }
}

SectionH.propTypes = {
  viewModel: PropTypes.object
};

SectionH.defaultProps = {
  viewModel: null
};

export default SectionH;
