import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import C01 from '../PC01';
import styles from './styles.module.scss';

export default function InfoRow(props) {
  return (
    <div className={cn(styles.flex, styles.flex_layout)}>
      <div className={cn(styles.flex_item)}>
        <px-posize
          track-style='{"flexGrow":1}'
          x="1fr minmax(0px, max-content) 1fr"
          y="0fr minmax(0px, max-content) 1fr"
          xs-x="1fr minmax(0px, max-content) 1fr"
          xs-y="0fr minmax(0px, max-content) 1fr"
        >
          <div className={cn(styles.info1)}>
            <C01
              hero_title={props.info.description1}
              descriptionUnit={props.info.description1Unit}
              text_body={props.info.intro1}
            />
          </div>
        </px-posize>
      </div>
      <div className={cn(styles.flex_spacer)} />
      <div className={cn(styles.flex_item)}>
        <px-posize
          track-style='{"flexGrow":1}'
          x="1fr minmax(0px, max-content) 1fr"
          y="1fr minmax(0px, max-content) 1fr"
          xs-x="1fr minmax(0px, max-content) 1fr"
          xs-y="1fr minmax(0px, max-content) 1fr"
        >
          <div className={cn(styles.info2)}>
            <C01
              hero_title={props.info.description2}
              descriptionUnit={props.info.description2Unit}
              text_body={props.info.intro2}
            />
          </div>
        </px-posize>
      </div>
      <div className={cn(styles.flex_spacer1)} />
      <div className={cn(styles.flex_item)}>
        <px-posize
          track-style='{"flexGrow":1}'
          x="1fr minmax(0px, max-content) 1fr"
          y="0fr minmax(0px, max-content) 0fr"
          xs-x="1fr minmax(0px, max-content) 1fr"
          xs-y="0fr minmax(0px, max-content) 0fr"
        >
          <div className={cn(styles.info3)}>
            <C01
              hero_title={props.info.description3}
              descriptionUnit={props.info.description3Unit}
              text_body={props.info.intro3}
            />
          </div>
        </px-posize>
      </div>
      <div className={cn(styles.flex_spacer)} />
      <div className={cn(styles.flex_item)}>
        <px-posize
          track-style='{"flexGrow":1}'
          x="1fr minmax(0px, max-content) 1fr"
          y="0fr minmax(0px, max-content) 1fr"
          xs-x="1fr minmax(0px, max-content) 1fr"
          xs-y="0fr minmax(0px, max-content) 1fr"
        >
          <div className={cn(styles.info4)}>
            <C01
              hero_title={props.info.description4}
              descriptionUnit={props.info.description4Unit}
              text_body={props.info.intro4}
            />
          </div>
        </px-posize>
      </div>
    </div>
  );
}


InfoRow.propTypes = {
  description1: PropTypes.string,
  description2: PropTypes.string,
  description3: PropTypes.string,
  description4: PropTypes.string,
  description1Unit: PropTypes.string,
  description2Unit: PropTypes.string,
  description3Unit: PropTypes.string,
  description4Unit: PropTypes.string,
  intro1: PropTypes.string,
  intro2: PropTypes.string,
  intro3: PropTypes.string,
  intro4: PropTypes.string
};

InfoRow.defaultProps = {
  description1: null,
  description2: null,
  description3: null,
  description4: null,
  description1Unit: null,
  description2Unit: null,
  description3Unit: null,
  description4Unit: null,
  intro1: null,
  intro2: null,
  intro3: null,
  intro4: null
};

