import React, { useState, useCallback, useEffect, useRef } from 'react';
import MediaQuery from 'react-responsive';

import isMobile from 'ismobilejs';

import styles from './styles.module.css';
import rotateAlert from '../../assets/rotateAlert.png';

export default function MobileHorizontalAlert() {
  const height = window.innerHeight;
  const divRef = useRef();
  const [isPhone] = useState(isMobile(window.navigator).phone);

  const preventWheel = useCallback((e) => {
    e.preventDefault();
  }, []);
  const preventTouch = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (isPhone) {
      divRef.current.addEventListener('wheel', preventWheel, { passive: false });
      return () => { divRef.current.removeEventListener('wheel', preventWheel); };
    }
    return () => {};
  }, [isPhone, preventWheel]);

  useEffect(() => {
    if (isPhone) {
      divRef.current.addEventListener('touchstart', preventTouch, { passive: false });
      return () => { divRef.current.removeEventListener('touchstart', preventTouch); };
    }
    return () => {};
  }, [isPhone, preventTouch]);

  return (
    <>
      {
        isPhone
          ? (
            <div ref={divRef}>
              <MediaQuery orientation="landscape" minWidth={height}>
                <div className={styles.container}>
                  <div className={styles.bg} />
                  <img
                    className={styles.rotateAlert}
                    src={rotateAlert}
                    alt="plz rotate ur device."
                  />
                </div>
              </MediaQuery>
            </div>
          )
          : null
      }
    </>
  );
}
