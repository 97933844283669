// import Order from './Order';
import Slot from './Slot';
import Space from './Space';

export default class Garage {
  constructor(params) {
    const {
      id,
      space,
      slot,
      orders
    } = params ?? {};

    this.id = id;
    this.space = Space.fromRes(space);
    this.slot = Slot.fromRes(slot);
    // this.orders = orders?.map((order) => Order.fromRes(order)) ?? [];
  }

  static fromRes(data) {
    return new Garage(data);
  }
}
