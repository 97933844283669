import React from 'react';

import { PopupType } from '../../constants';
import PopupPicText from './Types/PopupPicText';
import PopupText from './Types/PopupText';
import PopupPic from './Types/PopupPic';
import PopupChildren from './Types/PopupChildren';

function createPopup(viewModel) {
  const { type } = viewModel;
  switch (type) {
    case PopupType.TypePicText:
      return <PopupPicText viewModel={viewModel} />;
    case PopupType.TypeText:
      return <PopupText viewModel={viewModel} />;
    case PopupType.TypePic:
      return <PopupPic viewModel={viewModel} />;
    case PopupType.TypeChildren:
      return <PopupChildren viewModel={viewModel} />;
    default:
      throw new Error('can not find corresponding type popup.');
  }
}

export { createPopup };
