import { observable, action, makeObservable } from 'mobx';

import { SectionType } from 'src/constants';
import ISection from './ISection';

export default class SectionHViewModel extends ISection {
  @observable id;
  @observable picture;
  @observable title;
  @observable subtitle1;
  @observable color;
  @observable subtitle2;
  @observable content;
  @observable position;

  get type() {
    return SectionType.H;
  }

  constructor(props) {
    super(props);
    // makeObservable(this);
    this.deserialize(props);
  }

  onEnter = () => { };

  onLeave = () => { };

  @action
  deserialize = (params) => {
    const {
      id,
      picture,
      title,
      subtitle1,
      subtitle2,
      content,
      color,
      position
    } = params;

    this.id = id;
    this.picture = picture;
    this.title = title;
    this.subtitle1 = subtitle1;
    this.subtitle2 = subtitle2;
    this.content = content;
    this.color = color;
    this.position = position ?? 'center'; // 'left' | 'center' | 'right'
  }
}
