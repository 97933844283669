/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { createSlideView } from './Factory';
import './customSwiper.css';
import styles from './styles.module.css';

SwiperCore.use([Lazy]);

@observer
class Carousel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel } = this.props;

    return (
      <Swiper
        className={styles.container}
        onActiveIndexChange={viewModel.onSlideChange}
        onSwiper={viewModel.onSwiper}
        resizeObserver
        slidesPerView="auto"
        spaceBetween={20}
        cssMode={this.props.cssMode}
        lazy
        preloadImages={false}
      >
        {viewModel.slides.map((el, index) => (
          <SwiperSlide
            key={index}
            className={clsx(styles.slide, this.props.customSlide)}
          >
            {createSlideView(el, viewModel.slideRefs[index], this.props.className)}
          </SwiperSlide>
        ))}

      </Swiper>
    );
  }
}

Carousel.defaultProps = {
  viewModel: {},
  className: null,
  customSlide: null,
  cssMode: false
};

Carousel.propTypes = {
  viewModel: PropTypes.object,
  className: PropTypes.string,
  customSlide: PropTypes.string,
  cssMode: PropTypes.bool
};

export default Carousel;
