import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import PxCode from './P004';
import styles from './styles.module.css';

@observer
class SectionF extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.sectionContainer}>
        <PxCode
          indexDesign1={viewModel.indexDesign1}
          indexDesign2={viewModel.indexDesign2}
          indexDesign3={viewModel.indexDesign3}
          indexDesign4={viewModel.indexDesign4}
          indexDesign5={viewModel.indexDesign5}
        />

        <div className={styles.diagram_box}>設計風格示意圖</div>
      </div>
    );
  }
}

SectionF.propTypes = {
  viewModel: PropTypes.object
};

SectionF.defaultProps = {
  viewModel: null
};

export default SectionF;
