import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import PxCode from './P05';

@observer
class SectionC extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div>
        <PxCode
          indexdesignerbg={viewModel.indexdesignerbg}
          indexdesigner1={viewModel.indexdesigner1}
          indexdesigner2={viewModel.indexdesigner2}
          labeleg={viewModel.labeleg}
          labelct={viewModel.labelct}
          label={viewModel.label}
          title={viewModel.title}
          description={viewModel.description}
        />
      </div>
    );
  }
}

SectionC.propTypes = {
  viewModel: PropTypes.object
};

SectionC.defaultProps = {
  viewModel: null
};

export default SectionC;
