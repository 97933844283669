import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button } from 'antd';
import clsx from 'clsx';
import is from 'is_js';

import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import { themes } from './themes';
import styles from './styles.module.css';

Modal.setAppElement('#root');

export default function GlobalModal() {
  const { state } = useAppStateContext();
  const { modal } = state;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modal === null) {
      setVisible(false);
      return;
    }
    setVisible(true);

    if (modal?.theme === 'loading') {
      _onCancel();
    }
  }, [
    modal
  ]);

  const _onOk = async () => {
    setLoading(true);
    await modal?.onOk?.();
    setLoading(false);
    setVisible(false);
  };

  const _onCancel = async () => {
    // await new Promise((resolve) => setInterval(() => {
    //   resolve();
    // }, 1000));

    await modal?.onCancel?.();
    setVisible(false);
  };

  return (
    modal?.theme === 'loading'
      ? (
        <Modal
          isOpen={visible}
          className={clsx(styles.react_loading_modal)}
          overlayClassName={styles.react_modal_overlay}
        >
          <img src={themes.loading?.img} width={120} height={120} alt="loading" />
          <div className={clsx(styles.react_loading_modal_title)}>PLEASE WAIT...</div>
        </Modal>
      )
      : (
        <Modal
          isOpen={visible}
          onRequestClose={_onCancel}
          className={clsx(styles.react_modal_content, modal?.image ? styles.modal_img : themes[modal?.theme]?.imgClass)}
          overlayClassName={styles.react_modal_overlay}
          style={{
            content: { backgroundImage: `url(${modal?.image || themes[modal?.theme]?.img})` }
          }}
        >
          <div className={styles.text_layout}>
            <h3 className={styles.title}>{ modal?.title ? `${modal?.title}` : '' }</h3>
            <p className={styles.content}>{ modal?.content ? `${modal?.content}` : '' }</p>
          </div>
          <div className={clsx(styles.btn_layout, (is.string(modal?.cancelText) && is.string(modal?.okText) ? styles.btn_layout_end : styles.btn_layout_center))}>
            {
        is.string(modal?.cancelText) && (
          <Button onClick={_onCancel} className={styles.cancel_btn}>{modal?.cancelText}</Button>
        )
      }

            {
        is.string(modal?.okText) && (
          <Button onClick={_onOk} loading={loading} className={styles.ok_btn} style={{ backgroundColor: themes[modal?.theme]?.backgroundColor, borderColor: themes[modal?.theme]?.backgroundColor }}>{modal?.okText}</Button>
        )
      }
          </div>
        </Modal>
      )
  );
}


