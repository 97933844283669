import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

function Video(props, ref) {
  const { value } = props;
  const videoRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      onEnter() {
        videoRef.current.play();
      },
      onLeave() {
        videoRef.current.pause();
      }
    }),
    []
  );

  return (
    <video
      ref={videoRef}
      src={value}
      type="video/mp4"
      className={clsx(styles.layout)}
      loop
      muted
      controls
      autoPlay
    />
  );
}


export default forwardRef(Video);
